import React, { useCallback, useMemo, useRef, useState } from "react";

import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Chip, Popper, Skeleton, styled } from "@mui/material";

import SoftTypography from "../SoftTypography";
import SoftBox from "../SoftBox";

import { useClickAway } from "../../hooks";

export type SelectOption = { id: number | string; label: string; disabled?: boolean };

type SelectItemIdentifier = string | number | null | undefined;

type SelectProps = {
  options: SelectOption[];
  selected: SelectItemIdentifier;
  onSelect: (arg: SelectItemIdentifier) => void;
  placeholder?: string;
  customListItem?: (id: string | number, option?: SelectOption) => React.ReactNode;
  single?: boolean;
  loading?: boolean;
};

const Select: React.FC<SelectProps> = (props) => {
  const { options, placeholder, selected, onSelect, customListItem, single, loading } = props;

  const [open, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const ref = useRef<HTMLDivElement>(null);
  const popperRef = useRef<HTMLDivElement>(null);

  const openOptions = useCallback(() => {
    setAnchorEl(ref.current);
    setOpen(true);
  }, [ref.current]);

  const closeOptions = useCallback(() => {
    if (open) {
      setAnchorEl(null);
      setOpen(false);
    }
  }, [ref.current, open]);

  const handleItemClick = useCallback(
    (id: string | number) => {
      onSelect(id);
      closeOptions();
    },
    [open]
  );

  const handleDelete = useCallback(() => onSelect(null), [open]);

  const selectedItem = useMemo(() => options.find((o) => o.id === selected), [selected, options]);

  useClickAway([popperRef, ref], closeOptions);

  if (loading) {
    return (
      <SelectContainer>
        <SelectValue>
          <Skeleton width="100%" />
        </SelectValue>
      </SelectContainer>
    );
  }

  return (
    <SelectContainer>
      <SelectValue onClick={open ? closeOptions : openOptions} ref={ref}>
        {selectedItem ? (
          single ? (
            <CurrentValue>{selectedItem.label}</CurrentValue>
          ) : (
            <StyledChip
              label={selectedItem.label}
              onDelete={handleDelete}
              deleteIcon={<CloseRoundedIcon />}
              color="primary"
            />
          )
        ) : (
          <Placeholder>{placeholder}</Placeholder>
        )}

        <StyledExpandIcon
          sx={{
            transform: open ? "rotate(180deg)" : "none",
          }}
          fontSize="medium"
        />
      </SelectValue>

      <Popper
        open={open}
        anchorEl={anchorEl}
        sx={{ width: anchorEl?.clientWidth, zIndex: 1400 }}
        ref={popperRef}
      >
        <SelectOptions>
          {options.map((option, index) =>
            option.disabled ? (
              <SelectItem
                key={option.id}
                sx={(theme) => ({
                  backgroundColor: theme.palette.grey[100],
                  "&:hover": {
                    cursor: "not-allowed",
                  },
                  borderBottom:
                    index < options.length - 1 ? `1px solid ${theme.palette.grey[200]}` : "none",
                })}
              >
                {customListItem ? (
                  customListItem(option.id, option)
                ) : (
                  <React.Fragment>
                    <ItemText>{option.label}</ItemText>
                  </React.Fragment>
                )}

                {selected === option.id && <StyledCheckRoundedIcon color="primary" />}
              </SelectItem>
            ) : (
              <SelectItem
                key={option.id}
                onClick={() => handleItemClick(option.id)}
                sx={(theme) => ({
                  backgroundColor: selected === option.id ? "#eee9f8" : "none",
                  "&:hover": {
                    backgroundColor: selected !== option.id && theme.palette.grey[100],
                  },
                  borderBottom:
                    index < options.length - 1 ? `1px solid ${theme.palette.grey[200]}` : "none",
                })}
              >
                {customListItem ? (
                  customListItem(option.id, option)
                ) : (
                  <React.Fragment>
                    <ItemText>{option.label}</ItemText>
                  </React.Fragment>
                )}

                {selected === option.id && <StyledCheckRoundedIcon color="primary" />}
              </SelectItem>
            )
          )}
        </SelectOptions>
      </Popper>
    </SelectContainer>
  );
};

const SelectContainer = styled(SoftBox)(() => ({}));

const SelectValue = styled(SoftBox)(() => ({
  border: "0.0625rem solid #d2d6da",
  borderRadius: 4,
  padding: "0.5rem 0.75rem",
  height: "40px",
  cursor: "pointer",
  position: "relative",
  display: "flex",
  alignItems: "center",
}));

const StyledExpandIcon = styled(ExpandMoreRoundedIcon)(() => ({
  position: "absolute",
  right: 8,
  top: 8,
}));

const Placeholder = styled(SoftTypography)(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.grey[500],
}));

const CurrentValue = styled(SoftTypography)(({ theme }) => ({
  fontSize: 16,
  color: theme.palette.grey["900"],
}));

const SelectOptions = styled(SoftBox)(({ theme }) => ({
  backgroundColor: "#fff",
  borderRadius: 4,
  border: `1px solid ${theme.palette.grey[200]}`,
  maxHeight: 200,
  overflowX: "hidden",
  overflowY: "scroll",
}));

const SelectItem = styled(SoftBox)(({ theme }) => ({
  cursor: "pointer",
  padding: 10,
  position: "relative",
  display: "flex",
  alignItems: "center",
}));

const ItemText = styled(SoftTypography)(() => ({
  fontSize: 14,
  color: "#000",
}));

const StyledCheckRoundedIcon = styled(CheckRoundedIcon)(({ theme }) => ({
  position: "absolute",
  right: 10,
  top: "50%",
  transform: "translateY(-50%)",
  height: 14,
}));

const StyledChip = styled(Chip)(() => ({
  height: 22,
}));

export default Select;

import { useEffect, useState } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "../../../components/SoftBox";
import SoftTypography from "../../../components/SoftTypography";

// Soft UI Dashboard PRO React example components
import NonCollapsableNavBarLink from "../../../examples/Navbars/ApplicationNavBar/NonCollapsableNavBarLink";

import ApplicationsMenu from "./Menus/ApplicationsMenu";
import SoftAvatar, { stringAvatar } from "../../../components/SoftAvatar";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, Button, useTheme } from "@mui/material";
import { PRODUCT_NAME } from "../../../constants";
import { useUserData } from "../../../context/userDataContext";
import { useListings } from "../../../features/listings/contexts/ListingsContext";
import { useUnreadMessageContext } from "../../../features/inbox/contexts/UnreadMessagesProvider";
import { getCountTag } from "../../../features/inbox/utils/getCountTag";
import { PERMISSION_TYPES } from "../../../types/permissions.type";
import { useUncompletedTaskContext } from "../../../features/taskManager/contexts/UncompletedTasksProvider";
import { INTEGRATION_TYPE } from "../../../server/presistency/constants";
import SoftButton from "../../../components/SoftButton";
import Link from "next/link";

const CLOSE_MENU_TIME_IN_MS = 300;

export const ProfileAvatar = ({ size = "sm" }) => {
  const { userData } = useUserData();

  if (userData?.image) {
    return (
      <SoftAvatar
        src={userData?.image ?? "N/A"}
        size={size}
        alt="Avatar"
        sx={{ margin: 1 }}
        bgColor="primary"
      />
    );
  }

  if (userData?.name) {
    return (
      <SoftAvatar
        {...stringAvatar(userData?.name)}
        size={size}
        alt="Avatar"
        sx={{ margin: 1 }}
        bgColor="primary"
      />
    );
  }

  return (
    <SoftAvatar size={size} alt="Avatar" sx={{ margin: 1 }} bgColor="primary">
      N/A
    </SoftAvatar>
  );
};

function DefaultNavbar({ routes, transparent, light }) {
  const [applicationsMenu, setApplicationsMenu] = useState(false);
  const { palette } = useTheme();

  const openApplicationsMenu = ({ currentTarget }) => setApplicationsMenu(currentTarget);

  const closeApplicationsMenu = () =>
    setTimeout(() => {
      setApplicationsMenu(false);
    }, CLOSE_MENU_TIME_IN_MS);

  return (
    <SoftBox
      component={"nav"}
      sx={{ display: { xs: "none", lg: "flex" }, position: "sticky", top: 0, zIndex: 100 }}
    >
      <SoftBox
        py={1}
        px={{ xs: transparent ? 4 : 5, sm: transparent ? 2 : 5, lg: transparent ? 0 : 5 }}
        width="100%"
        shadow={transparent ? "none" : "md"}
        color={light ? "white" : "dark"}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={({
          palette: { transparent: transparentColor, white, background },
          functions: { rgba },
        }) => ({
          backgroundColor: background.default,
          backdropFilter: transparent ? "none" : `saturate(200%) blur(30px)`,
        })}
      >
        <SoftBox to="/" py={transparent ? 1.5 : 0.75} lineHeight={1}>
          <SoftTypography
            variant="button"
            fontWeight="bold"
            fontFamily={"Noto Sans"}
            sx={{ color: palette.text.heading_dark }}
          >
            {PRODUCT_NAME}.
          </SoftTypography>
        </SoftBox>

        <SoftBox color="inherit" display={{ xs: "none", lg: "flex" }} m={0} p={0}>
          <NavigationLinks light={light} />
        </SoftBox>

        <Box
          onClick={openApplicationsMenu}
          onMouseEnter={openApplicationsMenu}
          onMouseLeave={closeApplicationsMenu}
          sx={{ flexDirection: "row" }}
          display="flex"
          alignItems="center"
        >
          <SoftButton
            color="primary"
            size="small"
            onClick={() => {
              window.open("https://calendly.com/can-koseoglu", "_blank");
            }}
            sx={{
              height: { xs: 34, lg: 34 },
              padding: { xs: 0, lg: 2 },
              minWidth: 50,
              mr: 2,
              position: "relative",
            }}
          >
            BOOK A DEMO
          </SoftButton>
          <ProfileAvatar />
          <KeyboardArrowDownIcon sx={{ fontWeight: "bold", verticalAlign: "middle" }} />
        </Box>
      </SoftBox>
      <ApplicationsMenu routes={routes} open={applicationsMenu} close={closeApplicationsMenu} />
    </SoftBox>
  );
}

// Setting default values for the props of DefaultNavbar
DefaultNavbar.defaultProps = {
  transparent: false,
  light: false,
  action: false,
};

// Typechecking props for the DefaultNavbar
DefaultNavbar.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  transparent: PropTypes.bool,
  light: PropTypes.bool,
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(["external", "internal"]).isRequired,
      route: PropTypes.string.isRequired,
      color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
        "light",
      ]),
      label: PropTypes.string.isRequired,
    }),
  ]),
};

export default DefaultNavbar;

function NavigationLinks({ light }) {
  const { newListings } = useListings();
  const { unreadCount } = useUnreadMessageContext();
  const { uncompletedCount } = useUncompletedTaskContext();
  const { userData } = useUserData();

  return (
    <>
      <NonCollapsableNavBarLink
        tag={getCountTag(unreadCount)}
        name="inbox"
        light={light}
        href={"/app/inbox"}
        permissions={PERMISSION_TYPES.INBOX}
      />
      <NonCollapsableNavBarLink
        tag={""}
        name="integrations"
        light={light}
        href={"/app/integrations"}
        permissions={PERMISSION_TYPES.INTEGRATION_MANAGEMENT}
      />
      <NonCollapsableNavBarLink
        name="listings"
        light={light}
        href={"/app/listings"}
        tag={newListings > 0 ? `${newListings} new` : null}
        permissions={PERMISSION_TYPES.LISTING_MANAGEMENT}
      />
      <NonCollapsableNavBarLink
        tag={""}
        name="reservations"
        light={light}
        href={"/app/reservations"}
        permissions={PERMISSION_TYPES.RESERVATIONS}
      />
      <NonCollapsableNavBarLink
        tag={getCountTag(uncompletedCount)}
        name="tasks"
        light={light}
        basePath="/app/insights/task-manager"
        href={"/app/insights/task-manager"}
        permissions={PERMISSION_TYPES.TASK_MANAGER}
      />
      {userData?.integrationType !== INTEGRATION_TYPE.LODGIFY && (
        <NonCollapsableNavBarLink
          tag={""}
          name="reviews"
          light={light}
          basePath="/app/insights/recent-reviews"
          href={"/app/insights/recent-reviews"}
          permissions={PERMISSION_TYPES.REVIEW_ANALYSIS}
        />
      )}
      <NonCollapsableNavBarLink
        tag={"New"}
        name="chatbot"
        light={light}
        href={"/app/playground"}
        permissions={PERMISSION_TYPES.PLAYGROUND}
      />
    </>
  );
}

import React, { useEffect, useState } from "react";
import { useNotifications } from "../../notifications/hooks/useNotifications";
import { getErrorMessage } from "../../../utility/getErrorMessage";
import { getUncompletedTasks } from "../api/GET.UncompletedTasks";
import { useRealtimeContext } from "../../realtime/contexts/RealtimeContext";
import { SOCKET_MESSAGES_TYPES } from "../../../constants";
import { useRouter } from "next/router";
import { useUserData } from "../../../context/userDataContext";
import { PERMISSION_TYPES } from "../../../types/permissions.type";

type UncompletedTasksProviderProps = {
  children: React.ReactNode;
};

type UncompletedTasksContextType = {
  uncompletedCount: number;
  increaseUncompletedCount: () => void;
  decreaseUncompletedCount: () => void;
};

const UncompletedTasksContext = React.createContext<UncompletedTasksContextType>({
  uncompletedCount: 0,
  increaseUncompletedCount: () => null,
  decreaseUncompletedCount: () => null,
});

export const useUncompletedTaskContext = () => {
  const ctx = React.useContext(UncompletedTasksContext);

  if (!ctx) {
    console.error("Uncompleted tasks context is not configured");
  }

  return ctx;
};

export const UncompletedTasksProvider: React.FC<UncompletedTasksProviderProps> = ({ children }) => {
  const router = useRouter();
  const { canSee, userData } = useUserData();
  const { showErrorNotification } = useNotifications();
  const { subscribeToChannel, unsubscribeFromChannel } = useRealtimeContext();
  const [uncompletedCount, setUncompletedCount] = useState(0);

  const increaseUncompletedCount = () => setUncompletedCount((prev) => prev + 1);

  const decreaseUncompletedCount = () =>
    setUncompletedCount((prev) => (prev - 1 >= 0 ? prev - 1 : 0));

  const fetchUncompletedCount = async () => {
    try {
      if (!userData?.client || !canSee(PERMISSION_TYPES.TASK_MANAGER)) return;
      const { count } = await getUncompletedTasks();
      setUncompletedCount(count);
    } catch (err) {
      showErrorNotification({ text: getErrorMessage(err) });
    }
  };
  const unreadMessageEventCallback = () => {
    if (!router.asPath.includes("inbox")) {
      fetchUncompletedCount();
    }
  };

  useEffect(() => {
    fetchUncompletedCount();

    subscribeToChannel("task", [
      {
        event: SOCKET_MESSAGES_TYPES.TASK_CREATED_OR_UPDATED,
        callback: unreadMessageEventCallback,
      },
    ]);

    return () => {
      unsubscribeFromChannel("task");
    };
  }, []);

  return (
    <UncompletedTasksContext.Provider
      value={{ uncompletedCount, increaseUncompletedCount, decreaseUncompletedCount }}
    >
      {children}
    </UncompletedTasksContext.Provider>
  );
};

export default UncompletedTasksProvider;

import React from "react";
import { Modal, styled } from "@mui/material";
import SoftBox from "../SoftBox";

type ModalBaseProps = {
  open: boolean;
  onClose: () => void;
  backdropStyles?: React.CSSProperties;
  children: React.ReactNode;
  className?: string;
};

const ModalBase: React.FC<ModalBaseProps> = (props) => {
  const { open, onClose, children, backdropStyles, className } = props;

  return (
    <Modal
      open={open}
      onClose={onClose}
      componentsProps={{
        root: {
          style: {
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            overflowY: "auto",
            maxHeight: "100vh",
          },
        },
        backdrop: {
          style: { ...backdropStyles },
        },
      }}
    >
      <ModalInnerContainer className={className}>{children}</ModalInnerContainer>
    </Modal>
  );
};

const ModalInnerContainer = styled(SoftBox)(() => ({
  background: "#fff",
  padding: 20,
  margin: 20,
  borderRadius: 10,
  display: "flex",
  flexDirection: "column",
  minWidth: 320,
  maxWidth: 400,
  width: "100%",
  outline: "none",
}));

export default ModalBase;

import axios from "axios";
import { getServerPath } from "../../../constants";
import { Listing } from "../types/Listing";

export const updateListingInfo = async (listingId) => {
  const { data } = await axios.put<Listing>(
    getServerPath() + `/listings/update-listing/${listingId}`
  );

  return data;
};

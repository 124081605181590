import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import PropTypes from "prop-types";

import NotificationContainer from "../../features/notifications/components/NotificationContainer";
import ApplicationNavBar from "../../examples/Navbars/ApplicationNavBar";
import SoftBox from "components/SoftBox";
import Grid from "@mui/material/Grid";
import SideMenu from "./SideMenu";
import Footer from "./Footer";

import pageRoutes from "page.routes";
import InstallPwaPrompt from "../../features/pwa/components/InstallPwaPrompt";

const fullHeightRoutes = ["/insights", "/settings", "/playground"];

function IllustrationLayout({ children }) {
  const [sideMenuOpen, setSideMenuOpen] = useState(false);
  const router = useRouter();

  useEffect(() => {
    setSideMenuOpen(false);
  }, [router]);

  const getVerticalMargin = () => {
    const fullHeightRoute = fullHeightRoutes.filter((r) => router.asPath.includes(r));
    if (fullHeightRoute.length) {
      return 0;
    }
    return 3;
  };

  return (
    <SoftBox
      width="100vw"
      height="100%"
      minHeight="100vh"
      bgColor={"white"}
      sx={{ flexDirection: "column" }}
      display="flex"
    >
      <InstallPwaPrompt />
      <ApplicationNavBar routes={pageRoutes} />
      <SideMenu routes={pageRoutes} open={sideMenuOpen} />

      <NotificationContainer />

      <Grid container sx={{ flex: 1 }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{ mx: "auto", my: getVerticalMargin() }}
        >
          {children}
        </Grid>
      </Grid>

      <Footer isSideMenuOpen={sideMenuOpen} openSideMenu={() => setSideMenuOpen(!sideMenuOpen)} />
    </SoftBox>
  );
}

// Typechecking props for the IllustrationLayout
IllustrationLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default IllustrationLayout;

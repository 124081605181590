import type { AppProps } from "next/app";
import type { Session } from "next-auth";
import Head from "next/head";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "../assets/theme";

// 3rd party style files
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "../features/userManagement/styles/index.scss";
import "../features/notifications/styles/index.scss";
import "../features/inbox/styles/index.scss";
import "./globals.css";

// providers
import { CacheProvider, EmotionCache } from "@emotion/react";
import { SoftUIControllerProvider } from "../context";
import { SessionProvider } from "next-auth/react";

// custom providers
import AppProviders from "../features/providers";
import AppHOCs from "../hocs/appHOCs";

// utilities
import createEmotionCache from "../utility/createEmotionCache";
import axios from "axios";
import Script from "next/script";
import { FacebookPixelEvents } from "../components/FacebookPixel/FacebookPixelEvents";
import { isProduction } from "../utility/environment";
import * as Sentry from "@sentry/react";

const clientSideEmotionCache = createEmotionCache();

export interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
  session: Session;
}

// Use of the <SessionProvider> is mandatory to allow components that call
// `useSession()` anywhere in your application to access the `session` object.
export default function App(props: MyAppProps) {
  const {
    Component,
    pageProps: { session, ...pageProps },
    emotionCache = clientSideEmotionCache,
  } = props;

  if (axios && axios.interceptors && Sentry && Sentry.captureException) {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        Sentry.captureException(error);
        return Promise.reject(error);
      }
    );
  }

  return (
    //UserDataContext needs to be a child comp of SessionProvider and ThemeProvider
    <CacheProvider value={emotionCache}>
      <Head>
        <meta name="viewport" content="initial-scale=1, maximum-scale=1.0, width=device-width" />
        <title>botel.ai - Elevate guest experience</title>
        <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />
        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
      </Head>

      <Sentry.ErrorBoundary>
        <SessionProvider session={session}>
          <SoftUIControllerProvider>
            <ThemeProvider theme={theme}>
              <AppProviders>
                <AppHOCs>
                  <CssBaseline />
                  <Component {...pageProps} />
                </AppHOCs>
              </AppProviders>
            </ThemeProvider>
          </SoftUIControllerProvider>
        </SessionProvider>
      </Sentry.ErrorBoundary>

      {isProduction() && (
        <>
          <Script async src="https://www.googletagmanager.com/gtag/js?id=G-0E674HHQ1M" />
          <Script id="google-analytics">
            {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-0E674HHQ1M');
        `}
          </Script>

          <Script id="userguiding">
            {`(function (g, u, i, d, e, s) {
  g[e] = g[e] || [];
  var f = u.getElementsByTagName(i)[0];
  var k = u.createElement(i);
  k.async = true;
  k.src = "https://static.userguiding.com/media/user-guiding-" + s + "-embedded.js";
  f.parentNode.insertBefore(k, f);
  if (g[d]) return;
  var ug = (g[d] = { q: [] });
  ug.c = function (n) {
    return function () {
      ug.q.push([n, arguments]);
    };
  };
  var m = [
    "previewGuide",
    "finishPreview",
    "track",
    "identify",
    "hideChecklist",
    "launchChecklist",
  ];
  for (var j = 0; j < m.length; j += 1) {
    ug[m[j]] = ug.c(m[j]);
  }
})(window, document, "script", "userGuiding", "userGuidingLayer", "FUU79276PZJID");
`}
          </Script>
        </>
      )}

      <FacebookPixelEvents />
    </CacheProvider>
  );
}

export enum LocalStorageKeys {
  //the user looked at the tooltip about translations
  TRANSLATION_TOOLTIP_WAS_SHOWN = "translation_tooltip_was_shown",
}

export const LocalStorageService = {
  get: (key: string) => {
    const lsValue = localStorage.getItem(key);

    if (lsValue !== null) {
      const json = JSON.parse(lsValue);
      return json;
    }

    return null;
  },

  set: (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value));
  },

  delete: (key: string) => {
    localStorage.removeItem(key);
  },

  clearSessionData: () => {
    Object.keys(LocalStorageKeys).forEach((key) => {
      localStorage.removeItem(key);
    });
  },
};

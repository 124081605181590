import React from "react";

const NavigationIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.82845 8.32845C8.3601 8.79675 7.3808 9.77605 6.7069 10.45C6.31635 10.8405 5.68385 10.8407 5.2933 10.4501C4.63117 9.788 3.6708 8.82765 3.17158 8.32845C1.60948 6.76635 1.60948 4.23367 3.17158 2.67158C4.73367 1.10948 7.26635 1.10948 8.82845 2.67158C10.3905 4.23367 10.3905 6.76635 8.82845 8.32845Z"
      stroke="#7149C6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.5 5.5C7.5 6.32845 6.82845 7 6 7C5.17155 7 4.5 6.32845 4.5 5.5C4.5 4.67158 5.17155 4 6 4C6.82845 4 7.5 4.67158 7.5 5.5Z"
      stroke="#7149C6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default NavigationIcon;

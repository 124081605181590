import React from "react";

const AlertIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 0.249512C4.6239 0.249512 0.25 4.62342 0.25 9.99951C0.25 15.3756 4.6239 19.7495 10 19.7495C15.3761 19.7495 19.75 15.3756 19.75 9.99951C19.75 4.62342 15.3761 0.249512 10 0.249512ZM10.9375 15.2453H9.0625V13.3703H10.9375V15.2453ZM10.75 12.2495H9.25L8.96875 4.74951H11.0312L10.75 12.2495Z"
      fill="#C53030"
    />
  </svg>
);

export default AlertIcon;

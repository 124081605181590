import React from "react";

const IntegrationsIcon = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 10.582V3.58203L4 14.582H11V21.582L20 10.582H13Z"
      stroke="#111827"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IntegrationsIcon;

import { createContext, useContext, useState } from "react";

type GlobalLayoutContext = {
  footerVisible: boolean;
  setFooterVisibility: (arg: boolean) => void;
};

type GlobalLayoutContextProviderProps = {
  children: React.ReactNode;
};

const GlobalLayoutContext = createContext<GlobalLayoutContext>({
  footerVisible: true,
  setFooterVisibility: () => null,
});

export const useGlobalLayoutContext = () => {
  const ctx = useContext(GlobalLayoutContext);

  if (!ctx) {
    console.error("App layout context is not configured");
  }

  return ctx;
};

export const GlobalLayoutContextProvider: React.FC<GlobalLayoutContextProviderProps> = ({
  children,
}) => {
  const [footerVisible, setFooterVisibility] = useState(true);

  return (
    <GlobalLayoutContext.Provider value={{ footerVisible, setFooterVisibility }}>
      {children}
    </GlobalLayoutContext.Provider>
  );
};

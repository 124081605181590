import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";

import NotificationContext from "../context/NotificationContext";

import type { NewNotification } from "../types/NewNotification";
import type { Notification } from "../types/Notification";
import { useUserData } from "../../../context/userDataContext";

type NotificationsProviderProps = {
  children: React.ReactNode;
};

const NotificationsProvider: React.FC<NotificationsProviderProps> = ({ children }) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const { userData } = useUserData();

  const addNotification = (arg: NewNotification) => {
    const notificationId = uuid();
    const newNotification = { ...arg, id: notificationId };
    setNotifications((prev) => [...prev, newNotification]);

    setTimeout(() => {
      try {
        const htmlElements = document.getElementsByTagName("body");
        if (htmlElements && htmlElements.length) {
          htmlElements[0].scrollIntoView({ behavior: "smooth", block: "start" });
        }
      } catch (e) {
        //
      }
    }, 100);
  };

  const removeNotification = (id: string) => {
    setNotifications((prev) => prev.filter((n) => n.id !== id));
  };

  const checkIfShouldShowWelcomeMessage = () => {
    // TODO: improve this part
    if (
      userData &&
      (!userData.client?.client_integration || userData.client?.client_integration?.length === 0) &&
      !notifications.some((n) => n.id === "welcome")
    ) {
      const welcomeNotification: Notification = {
        id: "welcome",
        type: "info",
        text: "Welcome to Botel.  Lets get started by adding your listings, add your listings on ",
        link: {
          label: "integrations page.",
          to: "/app/integrations",
        },
      };

      setNotifications((prev) => [...prev, welcomeNotification]);
    }
  };

  useEffect(() => {
    if (userData) {
      checkIfShouldShowWelcomeMessage();
    }
  }, [userData]);

  return (
    <NotificationContext.Provider value={{ notifications, addNotification, removeNotification }}>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationsProvider;

import { AxiosError } from "axios";

export const getErrorMessage = (arg: unknown) => {
  if (arg instanceof AxiosError && arg?.response?.data?.open) {
    try {
      window.open(arg?.response?.data?.open);
    } catch (e) {
      window.location.href = arg?.response?.data?.open;
    }
  }

  if (arg instanceof AxiosError && arg?.response?.data?.message) {
    return arg?.response?.data?.message;
  }

  if (arg instanceof Error) {
    return arg.message;
  }

  try {
    return JSON.stringify(arg);
  } catch {
    return "Unknown error";
  }
};

import React from "react";
import LayoutHOC from "../layouts/app/LayoutHOC";
import WithCustomSW from "./WithCustomSW";
import ErrorBoundaryHOC from "./ErrorBoundary";

type AppHOCsProps = {
  children: React.ReactNode;
};

const AppHOCs: React.FC<AppHOCsProps> = ({ children }) => {
  return (
    <LayoutHOC>
      <ErrorBoundaryHOC>
        <WithCustomSW>{children}</WithCustomSW>
      </ErrorBoundaryHOC>
    </LayoutHOC>
  );
};

export default AppHOCs;

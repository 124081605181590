export const PERMISSION_TYPES = {
  INBOX: "inbox",
  RESERVATIONS: "reservations",
  SEND_MESSAGES: "sendMessages",
  BOT_SUGGESTIONS: "botSuggestions",
  INTEGRATION_MANAGEMENT: "integrationManagement",
  LISTING_MANAGEMENT: "listingManagement",
  INBOX_PAYOUTS: "inboxPayouts",
  USER_MANAGEMENT: "userManagement",
  BILLING_CLIENT: "billingClient",
  CHANGE_QUANTITY: "changeQuantity",
  MANAGE_PAYMENT: "managePayment",
  REVIEW_ANALYSIS: "reviewAnalysis",
  TASK_MANAGER: "taskManager",
  TASK_MANAGER_SUPER: "taskManagerSuper",
  PLAYGROUND: "playground",
  FAQ: "faq",
};

export const PERMISSION_TYPES_VALUES = Object.keys(PERMISSION_TYPES).map(
  (k) => PERMISSION_TYPES[k]
);

export type PermissionType = (typeof PERMISSION_TYPES_VALUES)[number];

export const PERMISSION_TYPE_LABELS: Record<PermissionType, string> = {
  [PERMISSION_TYPES.INBOX]: "Read messages",
  [PERMISSION_TYPES.RESERVATIONS]: "Can see reservations",
  [PERMISSION_TYPES.SEND_MESSAGES]: "Send messages",
  [PERMISSION_TYPES.BOT_SUGGESTIONS]: "Create AI response",
  [PERMISSION_TYPES.INTEGRATION_MANAGEMENT]: "Manage integrations",
  [PERMISSION_TYPES.LISTING_MANAGEMENT]: "Enable or disable AI/Task Manager/Auto-Preapprove",
  [PERMISSION_TYPES.INBOX_PAYOUTS]: "Inbox Payouts",
  [PERMISSION_TYPES.USER_MANAGEMENT]: "User management",
  [PERMISSION_TYPES.BILLING_CLIENT]: "Billing client",
  [PERMISSION_TYPES.CHANGE_QUANTITY]: "Can change quantity in billing page",
  [PERMISSION_TYPES.MANAGE_PAYMENT]: "Can manage payment details",
  [PERMISSION_TYPES.REVIEW_ANALYSIS]: "Review analysis",
  [PERMISSION_TYPES.TASK_MANAGER]: "Task manager",
  [PERMISSION_TYPES.TASK_MANAGER_SUPER]: "Full control in task manager",
  [PERMISSION_TYPES.PLAYGROUND]: "Playground",
  [PERMISSION_TYPES.FAQ]: "FAQ management",
};

export const USER_ROLES = {
  OWNER: "owner",
  OWNER_PLUS: "owner+",
  CLIENT: "client",
  ADMIN: "admin",
  ACCOUNTANT: "accountant",
  GUEST_RELATIONS: "guest_relations",
  GUEST_RELATIONS_PLUS: "guest_relations+",
  OPERATIONS: "operations",
  OPERATIONS_PLUS: "operations+",
  CLEANER: "cleaner",
  CLEANER_PLUS: "cleaner+",
};

export const USER_ROLE_LABELS = {
  [USER_ROLES.OWNER]: "Owner",
  [USER_ROLES.OWNER_PLUS]: "Owner+",
  [USER_ROLES.CLIENT]: "Client",
  [USER_ROLES.ADMIN]: "Admin",
  [USER_ROLES.ACCOUNTANT]: "Accountant",
  [USER_ROLES.GUEST_RELATIONS]: "Guest Relations",
  [USER_ROLES.GUEST_RELATIONS_PLUS]: "Guest Relations+",
  [USER_ROLES.OPERATIONS]: "Operations",
  [USER_ROLES.OPERATIONS_PLUS]: "Operations+",
  [USER_ROLES.CLEANER]: "Cleaner",
  [USER_ROLES.CLEANER_PLUS]: "Cleaner+",
};

export const USER_ROLES_VALUES = Object.keys(PERMISSION_TYPES).map((k) => PERMISSION_TYPES[k]);

export type Roles = (typeof USER_ROLES_VALUES)[number];

export type RoleDefinition = Record<Roles, PermissionType[]>;

export const ROLE_COLLECTION: RoleDefinition = {
  [USER_ROLES.OWNER_PLUS]: [
    PERMISSION_TYPES.INBOX,
    PERMISSION_TYPES.RESERVATIONS,
    PERMISSION_TYPES.INBOX_PAYOUTS,
    PERMISSION_TYPES.PLAYGROUND,
  ],
  [USER_ROLES.OWNER]: [PERMISSION_TYPES.RESERVATIONS],
  [USER_ROLES.CLIENT]: [
    PERMISSION_TYPES.INBOX,
    PERMISSION_TYPES.RESERVATIONS,
    PERMISSION_TYPES.SEND_MESSAGES,
    PERMISSION_TYPES.BOT_SUGGESTIONS,
    PERMISSION_TYPES.INTEGRATION_MANAGEMENT,
    PERMISSION_TYPES.LISTING_MANAGEMENT,
    PERMISSION_TYPES.INBOX_PAYOUTS,
    PERMISSION_TYPES.USER_MANAGEMENT,
    PERMISSION_TYPES.BILLING_CLIENT,
    PERMISSION_TYPES.CHANGE_QUANTITY,
    PERMISSION_TYPES.MANAGE_PAYMENT,
    PERMISSION_TYPES.REVIEW_ANALYSIS,
    PERMISSION_TYPES.TASK_MANAGER,
    PERMISSION_TYPES.TASK_MANAGER_SUPER,
    PERMISSION_TYPES.PLAYGROUND,
    PERMISSION_TYPES.FAQ,
  ],
  [USER_ROLES.ADMIN]: [
    PERMISSION_TYPES.INBOX,
    PERMISSION_TYPES.RESERVATIONS,
    PERMISSION_TYPES.SEND_MESSAGES,
    PERMISSION_TYPES.BOT_SUGGESTIONS,
    PERMISSION_TYPES.LISTING_MANAGEMENT,
    PERMISSION_TYPES.INBOX_PAYOUTS,
    PERMISSION_TYPES.BILLING_CLIENT,
    PERMISSION_TYPES.REVIEW_ANALYSIS,
    PERMISSION_TYPES.TASK_MANAGER,
    PERMISSION_TYPES.TASK_MANAGER_SUPER,
    PERMISSION_TYPES.PLAYGROUND,
    PERMISSION_TYPES.FAQ,
  ],
  [USER_ROLES.ACCOUNTANT]: [
    PERMISSION_TYPES.RESERVATIONS,
    PERMISSION_TYPES.BILLING_CLIENT,
    PERMISSION_TYPES.MANAGE_PAYMENT,
  ],
  [USER_ROLES.GUEST_RELATIONS]: [
    PERMISSION_TYPES.INBOX,
    PERMISSION_TYPES.RESERVATIONS,
    PERMISSION_TYPES.SEND_MESSAGES,
    PERMISSION_TYPES.BOT_SUGGESTIONS,
    PERMISSION_TYPES.TASK_MANAGER,
    PERMISSION_TYPES.PLAYGROUND,
  ],
  [USER_ROLES.GUEST_RELATIONS_PLUS]: [
    PERMISSION_TYPES.INBOX,
    PERMISSION_TYPES.RESERVATIONS,
    PERMISSION_TYPES.SEND_MESSAGES,
    PERMISSION_TYPES.BOT_SUGGESTIONS,
    PERMISSION_TYPES.INBOX_PAYOUTS,
    PERMISSION_TYPES.TASK_MANAGER,
    PERMISSION_TYPES.TASK_MANAGER_SUPER,
    PERMISSION_TYPES.PLAYGROUND,
  ],
  [USER_ROLES.OPERATIONS_PLUS]: [
    PERMISSION_TYPES.INBOX,
    PERMISSION_TYPES.RESERVATIONS,
    PERMISSION_TYPES.SEND_MESSAGES,
    PERMISSION_TYPES.REVIEW_ANALYSIS,
    PERMISSION_TYPES.TASK_MANAGER,
    PERMISSION_TYPES.TASK_MANAGER_SUPER,
    PERMISSION_TYPES.PLAYGROUND,
  ],
  [USER_ROLES.OPERATIONS]: [
    PERMISSION_TYPES.INBOX,
    PERMISSION_TYPES.RESERVATIONS,
    PERMISSION_TYPES.REVIEW_ANALYSIS,
    PERMISSION_TYPES.TASK_MANAGER,
    PERMISSION_TYPES.PLAYGROUND,
  ],
  [USER_ROLES.CLEANER]: [
    PERMISSION_TYPES.RESERVATIONS,
    PERMISSION_TYPES.REVIEW_ANALYSIS,
    PERMISSION_TYPES.TASK_MANAGER,
  ],
  [USER_ROLES.CLEANER_PLUS]: [
    PERMISSION_TYPES.INBOX,
    PERMISSION_TYPES.RESERVATIONS,
    PERMISSION_TYPES.SEND_MESSAGES,
    PERMISSION_TYPES.REVIEW_ANALYSIS,
    PERMISSION_TYPES.TASK_MANAGER,
    PERMISSION_TYPES.PLAYGROUND,
  ],
};

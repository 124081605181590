import React from "react";

const BillingIcon = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3 10.582H21M7 15.582H8M12 15.582H13M6 19.582H18C19.6569 19.582 21 18.2389 21 16.582V8.58203C21 6.92518 19.6569 5.58203 18 5.58203H6C4.34315 5.58203 3 6.92518 3 8.58203V16.582C3 18.2389 4.34315 19.582 6 19.582Z"
      stroke="#111827"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BillingIcon;

import React, { useEffect, useState } from "react";
import { Badge, BottomNavigation, BottomNavigationAction, Paper, useTheme } from "@mui/material";
import { useRouter } from "next/router";

import { useUnreadMessageContext } from "../../features/inbox/contexts/UnreadMessagesProvider";
import { useGlobalLayoutContext } from "../../features/ui/contexts/useGlobalLayoutContext";

import { getCountTag } from "../../features/inbox/utils/getCountTag";

import { NextLinkComposed } from "../../components/NextLinkComposed/NextLinkComposed";

import { InboxIcon, MenuIcon, ReservationIcon } from "../../assets/icons";
import SignalCellularAltOutlinedIcon from "@mui/icons-material/SignalCellularAltOutlined";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { useUserData } from "../../context/userDataContext";
import { PERMISSION_TYPES } from "../../types/permissions.type";
import { useUncompletedTaskContext } from "../../features/taskManager/contexts/UncompletedTasksProvider";
import { INTEGRATION_TYPE } from "../../server/presistency/constants";

// TODO:
// align all icons and correct paths

const Footer = ({ isSideMenuOpen, openSideMenu }) => {
  const router = useRouter();
  const { palette } = useTheme();
  const { unreadCount } = useUnreadMessageContext();
  const { uncompletedCount } = useUncompletedTaskContext();
  const { footerVisible } = useGlobalLayoutContext();
  const { canSee, userData } = useUserData();
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(router.asPath);
  }, [router.asPath]);

  useEffect(() => {
    if (isSideMenuOpen) {
      setValue("sidemenu");
    }
  }, [isSideMenuOpen]);

  if (!footerVisible) {
    return null;
  }

  return (
    <Paper
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        display: { lg: "none" },
        zIndex: 1201,
        pb: 2,
      }}
      elevation={3}
    >
      <BottomNavigation showLabels value={value}>
        {/* <BottomNavigationAction
          LinkComponent={NextLinkComposed}
          value="/app/dashboard"
          to={{ pathname: "/app/dashboard" }}
          label="Dashboard"
          icon={
            <HomeIcon
              color={router.asPath === "/app/dashboard" && !isSideMenuOpen && palette.primary.main}
            />
          }
        /> */}
        {canSee(PERMISSION_TYPES.INBOX) && (
          <BottomNavigationAction
            LinkComponent={NextLinkComposed}
            value="/app/inbox"
            to={{ pathname: "/app/inbox" }}
            label="Inbox"
            icon={
              <Badge
                badgeContent={getCountTag(unreadCount)}
                color="error"
                sx={{ ".MuiBadge-badge": { top: 5, fontSize: 6, fontWeight: 700, width: 12 } }}
              >
                <InboxIcon
                  color={
                    router.asPath.includes("/app/inbox") && !isSideMenuOpen && palette.primary.main
                  }
                />
              </Badge>
            }
          />
        )}

        {canSee(PERMISSION_TYPES.RESERVATIONS) && (
          <BottomNavigationAction
            LinkComponent={NextLinkComposed}
            value="/app/reservations"
            to={{ pathname: "/app/reservations" }}
            label="Reservations"
            icon={
              <ReservationIcon
                color={
                  router.asPath === "/app/reservations" && !isSideMenuOpen && palette.primary.main
                }
              />
            }
          />
        )}

        {canSee(PERMISSION_TYPES.TASK_MANAGER) && (
          <BottomNavigationAction
            LinkComponent={NextLinkComposed}
            value="/app/insights/task-manager"
            to={{ pathname: "/app/insights/task-manager" }}
            label="Tasks"
            icon={
              <Badge
                badgeContent={getCountTag(uncompletedCount)}
                color="error"
                sx={{ ".MuiBadge-badge": { top: 5, fontSize: 6, fontWeight: 700, width: 12 } }}
              >
                <AssignmentIcon
                  sx={{
                    height: 24,
                    fill:
                      router.asPath.includes("/app/insights/task-manager") &&
                      !isSideMenuOpen &&
                      palette.primary.main,
                  }}
                />
              </Badge>
            }
          />
        )}

        {canSee(PERMISSION_TYPES.REVIEW_ANALYSIS) &&
          userData?.integrationType !== INTEGRATION_TYPE.LODGIFY && (
            <BottomNavigationAction
              LinkComponent={NextLinkComposed}
              value="/app/insights/recent-reviews"
              to={{ pathname: "/app/insights/recent-reviews" }}
              label="Reviews"
              icon={
                <SignalCellularAltOutlinedIcon
                  sx={{
                    height: 24,
                    fill:
                      router.asPath.includes("review") && !isSideMenuOpen && palette.primary.main,
                  }}
                />
              }
            />
          )}

        <BottomNavigationAction
          onClick={openSideMenu}
          label="More"
          icon={<MenuIcon color={isSideMenuOpen && palette.primary.main} />}
        />
      </BottomNavigation>
    </Paper>
  );
};

export default Footer;

import React from "react";

const InboxIcon = ({ color }) => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.5 13V6C20.5 4.89543 19.6046 4 18.5 4H6.5C5.39543 4 4.5 4.89543 4.5 6V13M20.5 13V18C20.5 19.1046 19.6046 20 18.5 20H6.5C5.39543 20 4.5 19.1046 4.5 18V13M20.5 13H17.9142C17.649 13 17.3946 13.1054 17.2071 13.2929L14.7929 15.7071C14.6054 15.8946 14.351 16 14.0858 16H10.9142C10.649 16 10.3946 15.8946 10.2071 15.7071L7.79289 13.2929C7.60536 13.1054 7.351 13 7.08579 13H4.5"
      stroke={color || "#8392AB"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default InboxIcon;

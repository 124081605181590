import React from "react";
import type { Notification } from "../types/Notification";
import type { NewNotification } from "../types/NewNotification";

type NotificationContextType = {
  notifications: Notification[];
  addNotification: (arg: NewNotification) => void;
  removeNotification: (id: string) => void;
};

const NotificationContext = React.createContext<NotificationContextType>({
  notifications: [],
  addNotification: () => null,
  removeNotification: () => null,
});

export default NotificationContext;

export type ClientUser = {
  id?: string | null;
  userId?: string | null;
  invitationId?: number | null;
  name?: string | null;
  avatar?: string | null;
  email?: string | null;
  status?: string;
};

export const USER_PHONE_TYPE = {
  SMS: "sms",
  WHATSAPP: "whatsapp",
};

export const USER_PHONE_TYPE_LABELS = {
  [USER_PHONE_TYPE.SMS]: "SMS",
  [USER_PHONE_TYPE.WHATSAPP]: "Whatsapp",
};

export const getRequestParamSerializer = (params: Record<string, any>) => {
  let qs = "";
  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((v) => {
        if (v.invitationId) {
          qs += `${qs.length ? "&" : ""}${key}=${v.invitationId}`;
        } else if (v.userId) {
          qs += `${qs.length ? "&" : ""}${key}=${v.userId}`;
        } else {
          qs += `${qs.length ? "&" : ""}${key}=${v}`;
        }
      });
    } else if (typeof value === "object") {
      Object.entries(value).forEach(([k, v]) => {
        const parsedValue = v instanceof Date ? v.toISOString() : v;
        qs += `${qs.length ? "&" : ""}${k}=${parsedValue}`;
      });
    } else {
      qs += `${qs.length ? "&" : ""}${key}=${value}`;
    }
  });
  return qs;
};

/**
=========================================================
* Soft UI Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { forwardRef } from "react";

// Custom styles for SoftButton
import SoftButtonRoot from "./SoftButtonRoot";
import { ButtonProps } from "@mui/material/Button";
import { CircularProgress } from "@mui/material";

type SoftButtonProps = ButtonProps & {
  size?: "small" | "medium" | "large" | null;
  variant?: "text" | "contained" | "outlined" | "gradient";
  color?:
    | "white"
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning"
    | "error"
    | "light"
    | "dark";
  circular?: boolean;
  iconOnly?: boolean;
  children: React.ReactNode;
  target?: string;
  loading?: boolean;
};

type ButtonRef = HTMLButtonElement;

const SoftButton = forwardRef<ButtonRef, SoftButtonProps>(
  ({ loading, color, variant, size, circular, iconOnly, children, ...rest }, ref) => (
    <SoftButtonRoot
      {...rest}
      ref={ref}
      color="primary"
      // @ts-ignore
      variant={variant === "gradient" ? "contained" : variant}
      size={size}
      // @ts-ignore
      ownerState={{ color, variant, size, circular, iconOnly }}
      disabled={loading || rest.disabled}
    >
      {loading ? (
        <CircularProgress color={color === "primary" ? "secondary" : "primary"} size="1em" />
      ) : (
        children
      )}
    </SoftButtonRoot>
  )
);

// Setting default values for the props of SoftButton
SoftButton.defaultProps = {
  size: "medium",
  variant: "contained",
  circular: false,
  iconOnly: false,
};

export default SoftButton;

import React from "react";

const HelpIcon = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21 21.582L15 15.582M17 10.582C17 14.448 13.866 17.582 10 17.582C6.13401 17.582 3 14.448 3 10.582C3 6.71604 6.13401 3.58203 10 3.58203C13.866 3.58203 17 6.71604 17 10.582Z"
      stroke="#111827"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default HelpIcon;

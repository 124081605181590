import React from "react";
import PropTypes from "prop-types";
import Link from "next/link";
import SoftTypography from "../../../components/SoftTypography";
import SoftAlert from "../../../components/SoftAlert";
import { AlertIcon } from "../../../assets/icons";
import SoftBox from "../../../components/SoftBox";

import colors from "../../../assets/theme/base/colors";
import boxShadow from "../../../assets/theme/base/boxShadows";

const infoNotificationStyles = {
  alert: {
    background: `linear-gradient(124deg, ${colors.gradients.primary.main} -1.8%, ${colors.gradients.primary.state} 105.23%)`,
    boxShadow: boxShadow.xs,
  },
  text: {
    width: "100%",
    color: colors.white.main,
    lineHeight: 1.5,
    flex: 1,
  },
};

const successNotificationStyles = {
  alert: {
    background: `linear-gradient(124deg, ${colors.gradients.success.main} -1.8%, ${colors.gradients.success.state} 105.23%)`,
    boxShadow: boxShadow.xs,
  },
  text: {
    width: "100%",
    color: colors.white.main,
    lineHeight: 1.5,
    flex: 1,
  },
};

const errorNotificationStyles = {
  alert: {
    background: colors.alertColors.error.custom,
  },
  title: {
    fontWeight: 700,
    color: colors.black.main,
    fontSize: 16,
    lineHeight: 1.5,
    ml: 1.5,
  },
  text: {
    color: colors.black.main,
    fontSize: 16,
    lineHeight: 1.5,
    ml: 1.5,
  },
};

const Notification = (props) => {
  const { id, type, text, title, link, onDismiss, action, actionTitle, autoHide, duration } = props;

  const alertStyles = {
    ...(type === "info" && { ...infoNotificationStyles.alert }),
    ...(type === "error" && { ...errorNotificationStyles.alert }),
    ...(type === "success" && { ...successNotificationStyles.alert }),
    border: "none",
    borderRadius: 0,
    p: 2,
    height: 53,
  };

  const titleStyles = {
    ...(type === "info" && { ...infoNotificationStyles.title }),
    ...(type === "error" && { ...errorNotificationStyles.title }),
    ...(type === "success" && { ...successNotificationStyles.title }),
    fontSize: { xs: 12, md: 14, lg: 16 },
  };

  const textStyles = {
    ...(type === "info" && { ...infoNotificationStyles.text }),
    ...(type === "error" && { ...errorNotificationStyles.text }),
    ...(type === "success" && { ...successNotificationStyles.text }),
    fontSize: { xs: 12, md: 14, lg: 16 },
    textAlign: { xs: "start", md: "center" },
  };

  const textContainerStyles = {
    ...(type === "info" && { flex: 1 }),
    ...(type === "success" && { flex: 1 }),
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
  };

  if (autoHide) {
    setTimeout(() => {
      onDismiss(id);
    }, duration || 3000);
  }

  return (
    <SoftAlert
      dismissible={true}
      sx={{ ...alertStyles }}
      closeIconColor={type === "info" ? "white" : "black"}
      onDismiss={() => onDismiss(id)}
      id={`notification-${id}`}
    >
      {type === "error" && <AlertIcon />}

      <SoftBox sx={{ ...textContainerStyles }}>
        <SoftTypography sx={{ ...titleStyles }}>
          {title}

          {actionTitle && (
            <Link
              href="#"
              onClick={() => {
                onDismiss(id);
                action();
              }}
              style={{
                ...titleStyles,
                textDecoration: "underline",
                marginLeft: 8,
              }}
            >
              {actionTitle}
            </Link>
          )}
        </SoftTypography>
        <SoftTypography
          sx={{
            ...textStyles,
          }}
        >
          <span className="notification-text" dangerouslySetInnerHTML={{ __html: text }} />
          {link && (
            <Link
              href={link.to}
              style={{
                textDecoration: "underline",
                color: colors.white.main,
              }}
            >
              {link.label}
            </Link>
          )}
        </SoftTypography>
      </SoftBox>
    </SoftAlert>
  );
};

Notification.propTypes = {
  id: PropTypes.string,
  type: PropTypes.oneOf(["info", "error", "success"]),
  text: PropTypes.string,
  title: PropTypes.string,
  link: PropTypes.shape({
    to: PropTypes.string,
    label: PropTypes.string,
  }),
  onDismiss: PropTypes.func,
  actionTitle: PropTypes.string,
  action: PropTypes.func,
  autoHide: PropTypes.bool,
  duration: PropTypes.number,
};

export default Notification;

import React from "react";
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import { Card, CardContent, Divider, Drawer, List, SvgIcon, useTheme } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import { signOut } from "next-auth/react";

import {
  AccountSettingsIcon,
  BillingIcon,
  IntegrationsIcon,
  UserManagementIcon,
} from "../../assets/icons";

import Link from "next/link";
import SoftButton from "../../components/SoftButton";
import SoftAvatar from "../../components/SoftAvatar";

import { NavbarListingCard } from "../../features/listings/components/NavbarListingCard";
import { useUserData } from "../../context/userDataContext";
import { PERMISSION_TYPES } from "../../types/permissions.type";
import { ProfileAvatar } from "../../examples/Navbars/ApplicationNavBar";
import ChatBotIcon from "../../assets/icons/ChatBotIcon";

const pageRoutes = [
  // { key: "/app/chatbot-settings", title: "Chatbot Settings", icon: ChatBotIcon },
  { key: "/app/settings/account", title: "Account Settings", icon: AccountSettingsIcon },
  {
    key: "/app/settings/client",
    title: "Organization Settings",
    icon: IntegrationsIcon,
    permissions: [PERMISSION_TYPES.INTEGRATION_MANAGEMENT],
  },
  {
    key: "/app/integrations",
    title: "Integrations",
    icon: IntegrationsIcon,
    permissions: [PERMISSION_TYPES.LISTING_MANAGEMENT],
  },
  {
    key: "/app/settings/user-management",
    title: "User Management",
    icon: UserManagementIcon,
    permissions: [PERMISSION_TYPES.USER_MANAGEMENT],
  },
  {
    key: "/app/settings/chatbot-settings",
    title: "Chatbot Settings",
    icon: ChatBotIcon,
    permissions: [PERMISSION_TYPES.LISTING_MANAGEMENT],
  },
  {
    key: "/app/settings/urgent-tags",
    title: "Urgent Tags",
    icon: ChatBotIcon,
    permissions: [PERMISSION_TYPES.INTEGRATION_MANAGEMENT],
  },
  {
    key: "/app/settings/notification-settings",
    title: "Notification Settings",
    icon: IntegrationsIcon,
    permissions: [PERMISSION_TYPES.INTEGRATION_MANAGEMENT],
  },
  {
    key: "/app/settings/commission",
    title: "OTA Markups",
    icon: IntegrationsIcon,
    permissions: [PERMISSION_TYPES.LISTING_MANAGEMENT],
  },
  {
    key: "/app/settings/billing",
    title: "Billing",
    icon: BillingIcon,
    permissions: [PERMISSION_TYPES.BILLING_CLIENT],
  },
  // { key: "/app/help", title: "Help", icon: HelpIcon },
];

export const SideMenu = ({ open }) => {
  const { palette } = useTheme();
  const { userData, canSee } = useUserData();

  return (
    <Drawer
      PaperProps={{
        sx: {
          margin: 0,
          borderRadius: 0,
          width: "100vw",
          px: 2.5,
          pb: 2.5,
          boxShadow: "none",
          height: "calc(100dvh - 56px)",
        },
      }}
      ModalProps={{
        sx: {
          bottom: "calc(56px + env(safe-area-inset-bottom))",
          width: "100vw",
        },
      }}
      componentsProps={{
        backdrop: {
          sx: {
            background: "none",
            zIndex: 1,
          },
        },
      }}
      open={open}
    >
      <SoftBox sx={{ p: "40px 0 16px 0px" }}>
        <SoftTypography
          fontSize={24}
          variant="title"
          fontWeight="bold"
          sx={{ color: palette.dark.heading }}
        >
          Menu
        </SoftTypography>
      </SoftBox>

      <SoftBox>
        <Card sx={{ background: palette.grey[100] }}>
          <CardContent sx={{ background: palette.grey[100], py: 2.375, pl: 1.625, pr: 2.125 }}>
            <SoftBox display="flex" sx={{ position: "relative" }}>
              <SoftBox
                sx={{
                  width: 93,
                  height: 74,
                  zIndex: 2,
                  background: palette.grey[100],
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <ProfileAvatar size="xl" />
              </SoftBox>

              <SoftBox sx={{ ml: 2.25 }}>
                <SoftTypography
                  variant="h5"
                  fontWeight="bold"
                  sx={{ color: palette.dark.heading, mb: 0.25 }}
                  lineHeight="27px"
                >
                  {userData?.name}
                </SoftTypography>

                <SoftTypography fontSize={14} color="secondary" lineHeight="21px">
                  {userData?.email}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </CardContent>
        </Card>
      </SoftBox>

      <Divider />

      {canSee(PERMISSION_TYPES.LISTING_MANAGEMENT) && (
        <SoftBox>
          <NavbarListingCard />
        </SoftBox>
      )}

      <List sx={{ pt: 2 }}>
        {pageRoutes
          .filter((r) => canSee(r.permissions))
          .map((pr) => (
            <Link href={pr.key} key={pr.key}>
              <SoftBox display="flex" sx={{ alignItems: "center", py: 2 }}>
                <SoftAvatar variant="rounded" size="sm" sx={{ background: "#F8F9FA", mr: 1.5 }}>
                  <SvgIcon>{pr.icon()}</SvgIcon>
                </SoftAvatar>

                <SoftTypography
                  variant="h5"
                  fontWeight="bold"
                  sx={{ flex: 1, color: palette.text.secondary }}
                >
                  {pr.title}
                </SoftTypography>

                <KeyboardArrowRightIcon color="secondary" sx={{ width: 16 }} />
              </SoftBox>
            </Link>
          ))}
      </List>

      <Divider />

      <SoftButton variant="outlined" color="primary" onClick={signOut}>
        Log out
      </SoftButton>
    </Drawer>
  );
};

export default SideMenu;

import React from "react";

const LockIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.93685 9V10.3333M2.93685 13H10.9368C11.6732 13 12.2702 12.4031 12.2702 11.6667V7.66667C12.2702 6.93027 11.6732 6.33333 10.9368 6.33333H2.93685C2.20047 6.33333 1.60352 6.93027 1.60352 7.66667V11.6667C1.60352 12.4031 2.20047 13 2.93685 13ZM9.60352 6.33333V3.66667C9.60352 2.19391 8.40958 1 6.93685 1C5.46409 1 4.27018 2.19391 4.27018 3.66667V6.33333H9.60352Z"
        stroke="#8392AB"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default LockIcon;

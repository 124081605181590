import axios from "axios";
import { getServerPath } from "../../../constants";
import { ListingsFilters } from "../types/Listing";

export const connectAllListings = async (action: string, filters?: ListingsFilters) => {
  const { data } = await axios.post<{ count: number }>(getServerPath() + "/listings/connect-all", {
    action,
    filters,
  });
  return data;
};

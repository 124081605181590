import React from "react";

const AccountSettingsIcon = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.3246 4.89934C10.751 3.14293 13.249 3.14293 13.6754 4.89934C13.9508 6.03396 15.2507 6.57241 16.2478 5.96488C17.7913 5.02442 19.5576 6.79073 18.6172 8.33421C18.0096 9.33128 18.5481 10.6312 19.6827 10.9066C21.4391 11.333 21.4391 13.831 19.6827 14.2574C18.5481 14.5328 18.0096 15.8327 18.6172 16.8298C19.5576 18.3733 17.7913 20.1396 16.2478 19.1992C15.2507 18.5916 13.9508 19.1301 13.6754 20.2647C13.249 22.0211 10.751 22.0211 10.3246 20.2647C10.0492 19.1301 8.74926 18.5916 7.75219 19.1992C6.2087 20.1396 4.44239 18.3733 5.38285 16.8298C5.99038 15.8327 5.45193 14.5328 4.31731 14.2574C2.5609 13.831 2.5609 11.333 4.31731 10.9066C5.45193 10.6312 5.99037 9.33129 5.38285 8.33421C4.44239 6.79073 6.2087 5.02442 7.75219 5.96488C8.74926 6.5724 10.0492 6.03396 10.3246 4.89934Z"
      stroke="#111827"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 12.582C15 14.2389 13.6569 15.582 12 15.582C10.3431 15.582 9 14.2389 9 12.582C9 10.9251 10.3431 9.58203 12 9.58203C13.6569 9.58203 15 10.9251 15 12.582Z"
      stroke="#111827"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default AccountSettingsIcon;

import { ProductType } from "../../userManagement/types";

export const requiredManualUpdateListingSources = ["hostAway", "lodgify"];

export const LISTING_CONNECT_ACTIONS = {
  AI_RESPONSE_SUGGESTION: "is_enabled",
  TASK_MANAGER: "paid_for_analisys",
  AUTO_PRE_APPROVE: "is_auto_preapprove",
};

export const LISTING_CONNECT_ACTION_PRODUCTS = {
  [LISTING_CONNECT_ACTIONS.AI_RESPONSE_SUGGESTION]: ProductType.RESPONSE_SUGGESTION,
  [LISTING_CONNECT_ACTIONS.TASK_MANAGER]: ProductType.TASK_MANAGER,
  [LISTING_CONNECT_ACTIONS.AUTO_PRE_APPROVE]: ProductType.AUTO_PRE_APPROVE,
};

export const LISTING_CONNECT_ACTION_FIELDS = {
  [LISTING_CONNECT_ACTIONS.AI_RESPONSE_SUGGESTION]: "enabled",
  [LISTING_CONNECT_ACTIONS.TASK_MANAGER]: "paid_for_analisys",
  [LISTING_CONNECT_ACTIONS.AUTO_PRE_APPROVE]: "is_auto_preapprove",
};

export const LISTING_STATUS = {
  ENABLED: "enabled",
  DISCONNECTED: "disconnected",
  ACTIVE: "active",
  INACTIVE: "inactive",
};

export const LISTING_STATUS_OPTIONS = [
  { value: LISTING_STATUS.ACTIVE, label: "Active" },
  { value: LISTING_STATUS.INACTIVE, label: "Inactive" },
];

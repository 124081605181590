export const getEnvironment = () => {
  return process.env.VERCEL_ENV || process.env.NODE_ENV;
};

export const isProduction = () => {
  try {
    if (window !== undefined && window && window.location && window.location.hostname) {
      return window.location.hostname === "botel.ai";
    }
  } catch (e) {
    //
  }

  return getEnvironment() === "production";
};

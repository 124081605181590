export const INTEGRATION_TYPES = {
  GUESTY: { name: "Guesty", type: "guesty" },
  LODGIFY: { name: "Lodgify", type: "lodgify" },
  AIRBNB: { name: "Airbnb", type: "airbnb" },
  BOOKING_COM: { name: "Booking.com", type: "booking" },
  HOSTAWAY: { name: "Hostaway", type: "hostAway" },
  WHATSAPP: { name: "WhatsApp", type: "WhatsApp" },
  SLACK: { name: "Slack", type: "slack" },
  STRIPE: { name: "Stripe", type: "stripe" },
  JIRA: { name: "Jira", type: "jura" },
};

export const INTEGRATION_STATUSES = {
  INITIAL: "INITIAL",
  TOKEN_ADDED: "TOKEN_ADDED",
  WEBHOOKS_CREATING: "WEBHOOKS_CREATING",
  WEBHOOKS_CREATED: "WEBHOOKS_CREATED",
  LISTINGS_IMPORTING: "LISTINGS_IMPORTING",
  LISTINGS_IMPORTED: "LISTINGS_IMPORTED",
  REVIEWS_IMPORTING: "REVIEWS_IMPORTING",
  REVIEWS_IMPORTED: "REVIEWS_IMPORTED",
  CONVERSATIONS_IMPORTING: "CONVERSATIONS_IMPORTING",
  ACTIVE: "ACTIVE",
  AI_ENABLED: "AI_ENABLED",
  CHATBOT_CONFIGURED: "CHATBOT_CONFIGURED",
  AUTOPILOT_ENABLED: "AUTOPILOT_ENABLED",
};

export const INTEGRATION_STATUS = {
  CONNECTED: "Connected",
  DISCONNECTED: "Disconnected",
  COMING_SOON: "Coming soon",
  DISABLED: "Disabled",
};

export const SOCKET_MESSAGES_TYPES = {
  // event of message recieved from user to our client via Guesty or equivalent
  MESSAGE_RECIEVED: "messageReceived",
  // event of message sent from our client to a user via Guesty or equivalent
  MESSAGE_SENT: "messageSent",
  RESERVATION_NEW: "reservationNew",
  RESERVATION_UPDATED: "reservationUpdated",
  CONVERSATION_READ: "conversationRead",
  UPDATE_UNREAD: "updateUnread",
  LISTINGS_FETCHED: "listingFetched",
  SUBSCRIPTION_STARTED: "subscriptionStarted",
  SUBSCRIPTION_CANCELED: "subscriptionCanceled",
  INTEGRATION_STATUS_UPDATED: "integrationStatusUpdated",
  TASK_CREATED_OR_UPDATED: "taskCreatedOrUpdated",
};

import { SERVER_PATH } from "./urls";

export * from "./urls";

export const getServerPath = () => window.location.origin + SERVER_PATH;

export const EXTERNAL_URLS = {
  GUESTY_NEW_INTEGRATION_URL: "https://app.guesty.com/integrations/oauth-apps/new",
  LODGIFY_AUTH_INTEGRATION: "https://docs.lodgify.com/docs/authorization",
  HOSTAWAY_AUTH_INTEGRATION:
    "https://support.hostaway.com/hc/en-us/articles/360002576293-Hostaway-Public-API-Account-Secret-key-generation",
  WHATSAPP_INTEGRATION: "https://developers.facebook.com/docs/whatsapp/on-premises/get-started/",
};

export const CONTACTS = {
  SALES_EMAIL: "sales@botel.ai",
};

export const PRODUCT_NAME = "botel";

export const MESSAGE_ACTIONS = {
  DECLINE: "decline",
  EDIT: "edit",
  REGENERATE: "regenerate",
  APPROVE: "approve",
};

export const MESSAGE_SENDER = {
  BOT: "bot",
  GUEST: "guest",
  CLIENT: "client",
  LOG: "log",
  CHANNEL: "channel",
};

export const ISSUE_STATUS = {
  SOLVED: "solved",
  WORKING: "working",
  NEW: "new",
  REVIEWED: "reviewed",
};

export const AI_STATUS = {
  AI: "AI",
  AUTO_PILOT: "Auto Pilot",
};

export const ISSUE_STATUS_LIST = [
  {
    label: "New",
    value: ISSUE_STATUS.NEW,
  },
  {
    label: "Reviewed",
    value: ISSUE_STATUS.REVIEWED,
  },
  {
    label: "Solved",
    value: ISSUE_STATUS.SOLVED,
  },
];

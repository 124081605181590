import React, { useState } from "react";
import Link from "next/link";
import { Card, CardContent, useTheme } from "@mui/material";

import SoftBox from "../../../../components/SoftBox";
import SoftButton from "../../../../components/SoftButton";
import SoftTypography from "../../../../components/SoftTypography";

import ListingCardImages from "./ListingCardImages";
import ListingCardButton from "./ListingCardButton";
import { useListings } from "../../contexts/ListingsContext";

const NavbarListingCard = () => {
  const { palette } = useTheme();
  const { listings, connected } = useListings();

  const getRandomListingImage = (index) => {
    if (listings.length === 0) {
      return null;
    }
    const enabledListings = listings.filter((l) => l.enabled);

    return enabledListings[index]?.picture || null;
  };

  const getActionButtonVariant = () => {
    if (listings.length === 0) return "empty";
    if (connected.total === listings.length) return "add";
    if (connected.total > 0 && connected.total < listings.length) return "enable_ai";
    // TODO: setup_auto_pilot
  };

  return (
    <Card sx={{ background: palette.grey[100] }}>
      <CardContent sx={{ background: palette.grey[100], py: 2.375, pl: 1.625, pr: 2.125 }}>
        <SoftBox display="flex" sx={{ mb: 1.5, position: "relative" }}>
          <ListingCardImages
            firstImage={getRandomListingImage(0)}
            secondImage={getRandomListingImage(1)}
          />

          <SoftBox sx={{ ml: 2.25 }}>
            <SoftTypography
              variant="h5"
              fontWeight="bold"
              sx={{ color: palette.dark.heading, mb: 0.25 }}
              lineHeight="27px"
            >
              All listings
            </SoftTypography>

            <SoftTypography fontSize={14} color="secondary" lineHeight="21px">
              {listings.length > 0
                ? `${connected.connected} out of ${connected.total} are connected`
                : "No listings yet"}
            </SoftTypography>

            <SoftButton
              color="primary"
              variant="outlined"
              sx={{
                width: 67,
                height: 25,
                minHeight: 25,
                textTransform: "capitalize",
                fontSize: 14,
                p: 0,
              }}
              component={Link}
              href="/app/listings"
            >
              See all
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </CardContent>
    </Card>
  );
};

export default NavbarListingCard;

import axios from "axios";
import { getServerPath } from "../../../constants";

type GetUnreadMessagesResponse = {
  count: number;
};

export const getUnreadMessages = async () => {
  try {
    const { data } = await axios.get<GetUnreadMessagesResponse>(
      getServerPath() + "/inbox/conversations/unread"
    );

    return data;
  } catch (err) {
    throw err;
  }
};

import React, { useEffect, useRef } from "react";
import { getPusherInstance } from "../services/pusherService";
import type Pusher from "pusher-js/types/src/core/pusher";
import { useUserData } from "../../../context/userDataContext";

export type RealtimeContextType = {
  subscribeToChannel: (
    channel: string,
    binds: { event: string; callback: (arg?: any) => void }[]
  ) => void;
  unsubscribeFromChannel: (channel: string) => void;
};

type RealtimeContextProviderProps = {
  children: React.ReactNode;
};

const RealtimeContext = React.createContext<RealtimeContextType>({
  subscribeToChannel: () => null,
  unsubscribeFromChannel: () => null,
});

export const useRealtimeContext = () => {
  const ctx = React.useContext(RealtimeContext);

  if (!ctx) {
    console.error("Realtime context is not configured");
  }

  return ctx;
};

export const RealtimeContextProvider: React.FC<RealtimeContextProviderProps> = ({ children }) => {
  const realtimeProvider = useRef<Pusher | null>(null);

  const { userData } = useUserData();

  useEffect(() => {
    return () => {
      if (realtimeProvider.current) {
        realtimeProvider.current.disconnect();
      }
    };
  }, []);

  const subscribeToChannel = (
    channelName: string,
    binds: { event: string; callback: () => void }[]
  ) => {
    if (!realtimeProvider.current) {
      realtimeProvider.current = getPusherInstance();
    }

    if (realtimeProvider.current && userData?.client?.id) {
      const subscriptionChannelName = `${userData?.client?.id}-${channelName}`;

      const channel = realtimeProvider.current.subscribe(subscriptionChannelName);

      binds.forEach((b) => {
        channel.bind(b.event, b.callback);
      });

      console.log("subscribed successfully - channel ", subscriptionChannelName);
    }
  };

  const unsubscribeFromChannel = (channelName: string) => {
    if (realtimeProvider.current && userData?.client?.id) {
      const subscriptionChannelName = `${userData?.client?.id}-${channelName}`;

      realtimeProvider.current.unsubscribe(subscriptionChannelName);

      console.log("unsubscribed from channel - ", subscriptionChannelName);
    }
  };

  return (
    <RealtimeContext.Provider value={{ subscribeToChannel, unsubscribeFromChannel }}>
      {children}
    </RealtimeContext.Provider>
  );
};

import React from "react";
import NotificationsProvider from "../notifications/components/NotificationsProvider";
import { UserContextProvider } from "../../context/userDataContext";
import { ListingsContextProvider } from "../listings/contexts/ListingsContext";
import UnreadMessagesProvider from "../inbox/contexts/UnreadMessagesProvider";
import { RealtimeContextProvider } from "../realtime/contexts/RealtimeContext";
import { GlobalLayoutContextProvider } from "../ui/contexts/useGlobalLayoutContext";
import { WithPwaPromptContextProvider } from "../pwa/HOC/WithPwaPrompt";
import UncompletedTasksProvider from "../taskManager/contexts/UncompletedTasksProvider";

const AppProviders = ({ children }) => {
  return (
    <WithPwaPromptContextProvider>
      <NotificationsProvider>
        <UserContextProvider>
          <RealtimeContextProvider>
            <GlobalLayoutContextProvider>
              <UncompletedTasksProvider>
                <UnreadMessagesProvider>
                  <ListingsContextProvider>{children}</ListingsContextProvider>
                </UnreadMessagesProvider>
              </UncompletedTasksProvider>
            </GlobalLayoutContextProvider>
          </RealtimeContextProvider>
        </UserContextProvider>
      </NotificationsProvider>
    </WithPwaPromptContextProvider>
  );
};

export default AppProviders;

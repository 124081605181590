/**
 =========================================================
 * Soft UI Dashboard PRO React - v4.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

/**
 All of the routes for the page layout of Soft UI Dashboard PRO React are added here,
 You can add a new route, customize the routes and delete the routes here.

 Once you add a new route on this file it will be visible automatically on
 the DefaultNavbar.

 For adding a new route you can follow the existing routes in the routes array.
 1. The `type` key with the `collapse` value is used for a route.
 2. The `name` key is used for the name of the route on the DefaultNavbar.
 3. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
 4. The `icon` key is used for the icon of the route on the DefaultNavbar, you have to add a node.
 5. The `collapse` key is used for making a collapsible item on the DefaultNavbar that contains other routes inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
 6. The `route` key is used to store the route location which is used for the react router.
 7. The `href` key is used to store the external links location.
 */

// Soft UI Dashboard PRO React icons

import Shop from "examples/Icons/Shop";
import Document from "examples/Icons/Document";
import Cube from "examples/Icons/Cube";
import SpaceShip from "examples/Icons/SpaceShip";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
//Twice
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import { PERMISSION_TYPES } from "./types/permissions.type";
import EditNotificationsIcon from "@mui/icons-material/EditNotifications";
import SystemSecurityUpdateWarningIcon from "@mui/icons-material/SystemSecurityUpdateWarning";

const pageRoutes = [
  {
    name: "Applications",
    key: "applications",
    collapse: [
      // {
      //   name: "Add listing",
      //   key: "add_listing",
      //   route: "/app/listings",
      //   Icon: AddCircleOutlineIcon,
      // },
      // {
      //   name: "Chatbot settings",
      //   key: "chatbot_settings",
      //   route: "/app/settings/chatbot",
      //   Icon: GroupsOutlinedIcon,
      // },
      {
        name: "Account settings",
        key: "account_settings",
        route: "/app/settings/account",
        Icon: SettingsIcon,
      },
      {
        name: "User management",
        key: "user_management",
        route: "/app/settings/user-management",
        Icon: GroupsOutlinedIcon,
        permissions: [PERMISSION_TYPES.USER_MANAGEMENT],
      },
      {
        name: "Chatbot settings",
        key: "chatbot_settings",
        route: "/app/settings/chatbot-settings",
        Icon: SmartToyIcon,
        permissions: [PERMISSION_TYPES.LISTING_MANAGEMENT],
      },
      {
        name: "Urgent tags",
        key: "urgent_tags",
        route: "/app/settings/urgent-tags",
        Icon: SystemSecurityUpdateWarningIcon,
        permissions: [PERMISSION_TYPES.LISTING_MANAGEMENT],
      },
      {
        name: "Notification settings",
        key: "notification_settings",
        route: "/app/settings/notification-settings",
        Icon: EditNotificationsIcon,
        permissions: [PERMISSION_TYPES.INTEGRATION_MANAGEMENT],
      },
      {
        name: "Billing",
        key: "billing",
        route: "/app/settings/billing",
        Icon: PaymentOutlinedIcon,
        permissions: [
          PERMISSION_TYPES.BILLING_CLIENT,
          PERMISSION_TYPES.CHANGE_QUANTITY,
          PERMISSION_TYPES.MANAGE_PAYMENT,
        ],
      },
      // {
      //   name: "Help",
      //   key: "help",
      //   route: "/app/help",
      //   Icon: HelpOutlineIcon,
      // },
    ],
  },
  {
    name: "Docs",
    key: "docs",
    collapse: [
      {
        name: "Getting Started",
        key: "getting-started",
        href: "https://www.creative-tim.com/learning-lab/react/quick-start/soft-ui-dashboard/",
        description: "All about overview, quick start, license and contents",
        icon: <SpaceShip size="15px" color="secondary" />,
      },
      {
        name: "Foundation",
        key: "foundation",
        href: "https://www.creative-tim.com/learning-lab/react/colors/soft-ui-dashboard/",
        description: "See our colors, icons and typography",
        icon: <Document size="15px" color="secondary" />,
      },
      {
        name: "Components",
        key: "components",
        href: "https://www.creative-tim.com/learning-lab/react/alerts/soft-ui-dashboard/",
        description: "Explore our collection of fully designed components",
        icon: <Cube size="15px" color="secondary" />,
      },
      {
        name: "Plugins",
        key: "plugins",
        href: "https://www.creative-tim.com/learning-lab/react/datepicker/soft-ui-dashboard/",
        description: "Check how you can integrate our plugins",
        icon: <Shop size="15px" color="secondary" />,
      },
    ],
  },
];

export default pageRoutes;

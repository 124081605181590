import React from "react";
import PropTypes from "prop-types";
import Image from "next/image";
import SoftBox from "../../../../components/SoftBox";
import { useTheme } from "@mui/material";

import { PhotoIcon } from "../../../../assets/icons";

const ListingCardImages = (props) => {
  const { firstImage, secondImage } = props;

  const { palette } = useTheme();

  const FirstImageContent = () =>
    !firstImage ? (
      <PhotoIcon />
    ) : (
      <Image src={firstImage} fill style={{ borderRadius: 6 }} alt="listing" />
    );

  const SecondImageContent = () =>
    secondImage ? <Image src={secondImage} fill style={{ borderRadius: 6 }} alt="listing" /> : null;

  return (
    <>
      <SoftBox
        sx={{
          border: `2px solid ${palette.grey[200]}`,
          borderRadius: 1.5,
          width: 93,
          height: 74,
          zIndex: 2,
          background: palette.grey[100],
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <FirstImageContent />
      </SoftBox>
      <SoftBox
        sx={{
          border: `2px solid ${palette.grey[200]}`,
          borderRadius: 1.5,
          width: 93,
          height: 74,
          position: "absolute",
          zIndex: 1,
          top: 8,
          left: 8,
        }}
      >
        <SecondImageContent />
      </SoftBox>
    </>
  );
};

ListingCardImages.propTypes = {
  firstImage: PropTypes.string,
  secondImage: PropTypes.string,
};

export default ListingCardImages;

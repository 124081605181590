import React from "react";

const UserManagementIcon = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 7.58203C16 9.79117 14.2091 11.582 12 11.582C9.79086 11.582 8 9.79117 8 7.58203C8 5.37289 9.79086 3.58203 12 3.58203C14.2091 3.58203 16 5.37289 16 7.58203Z"
      stroke="#111827"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 14.582C8.13401 14.582 5 17.716 5 21.582H19C19 17.716 15.866 14.582 12 14.582Z"
      stroke="#111827"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default UserManagementIcon;

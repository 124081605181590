import React, { useEffect } from "react";
import { usePathname, useSearchParams } from "next/navigation";

export const FacebookPixelEvents: React.FC = () => {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID) {
      import("react-facebook-pixel")
        .then((x) => x.default)
        .then((ReactPixel) => {
          // @ts-ignore
          ReactPixel.init(process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID);
          ReactPixel.pageView();
        });
    }
  }, [pathname, searchParams]);

  return null;
};

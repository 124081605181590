import { createContext, useContext, useEffect, useState } from "react";
import { getSession } from "next-auth/react";
import { OverlayLoader } from "../components/OverlayLoader";
import Box from "@mui/material/Box";
import axios from "axios";
import { getServerPath, INTEGRATION_STATUSES } from "../constants";
import { client as ClientDao, client_integration as ClientIntegration } from "@prisma/client";
import { useNotifications } from "../features/notifications/hooks/useNotifications";
import { getErrorMessage } from "../utility/getErrorMessage";
import { useRouter } from "next/router";
import type { PushSubscription } from "../features/notifications/types/PushSubscription";
import { differenceInDays } from "date-fns";
import connectOrDisconnectIntegration from "../features/wizard/api/POST.connectOrDisconnectIntegration";
import { PermissionType } from "../types/permissions.type";
import UserPhoneModal from "../features/userManagement/components/UserPhoneModal";

type Client = ClientDao & { client_integration: ClientIntegration[] };

export type UserData = {
  id: string;
  email: string;
  image?: string;
  name: string;
  role: string;
  permissions: PermissionType[];
  client: Client | null;
  pushSubscriptions: PushSubscription[];
  isTrial?: boolean;
  trialEnd?: string;
  integrationType?: string;
  integrationStatus?: string;
  activeListingCount?: number;
  phone?: string;
  phoneType?: string;
  isPhoneVerified?: boolean;
};

export const getUserData = async () => {
  const session = await getSession();

  // @ts-ignore
  if (session && !session?.userId) {
    throw new Error(
      `User ${session?.user?.email} does not exist. Please try to log in using another account or contact us`
    );
  }

  const data = await axios // @ts-ignore
    .get(getServerPath() + `/user/${session?.userId}`)
    .then((res) => res.data)
    .catch(() => null);

  // @ts-ignore;
  const isTrial = session && session.isTrial;
  // @ts-ignore;
  const trialEnd = session && session.trialEnd;
  // @ts-ignore;
  const activeListingCount = session && session.activeListingCount;
  // @ts-ignore;
  const integrationType = session && session.integrationType;
  // @ts-ignore;
  const integrationStatus = session && session.integrationStatus;
  // @ts-ignore
  const permissions = session?.permissions || [];

  // @ts-ignore
  return session
    ? ({
        ...data,
        isTrial,
        trialEnd,
        integrationType,
        integrationStatus,
        permissions,
        activeListingCount,
      } as UserData)
    : null;
};

type UserContextType = {
  userData: UserData | null;
  changeIntegrationStatus: (integrationType: string, enabled: boolean) => void;
  setNeedRefresh: () => void;
  refreshUserData: () => Promise<UserData | null>;
  canSee: (
    permission?: PermissionType | PermissionType[],
    listingId?: number | number[]
  ) => boolean;
  authorize: (
    permission?: PermissionType | PermissionType[],
    listingId?: number | number[]
  ) => void;
  showPhoneModal: boolean;
  setShowPhoneModal: (showPhoneModal: boolean) => void;
};

const UserDataContext = createContext<UserContextType>({
  userData: null,
  changeIntegrationStatus: () => null,
  setNeedRefresh: () => null,
  refreshUserData: () => Promise.resolve(null),
  canSee: () => false,
  authorize: () => null,
  showPhoneModal: false,
  setShowPhoneModal: () => null,
});

export const UserContextProvider = ({ children }: { children: React.ReactNode }) => {
  const router = useRouter();

  const [userData, setUserData] = useState<UserData | null>(null);
  const [loading, setLoading] = useState(true);
  const [needRefresh, setRefresh] = useState(false);
  const [isTrial, setIsTrial] = useState(false);
  const { notifications, showErrorNotification, showInfoNotification } = useNotifications();
  const [showPhoneModal, setShowPhoneModal] = useState(false);

  const setNeedRefresh = () => {
    setRefresh(true);
  };

  const refreshUserData = async () => {
    try {
      const data = await getUserData();
      if (data) {
        setUserData(data);

        if (router.asPath === "/") {
          router.push("/app/inbox").then(() => {
            setLoading(false);
          });
        } else {
          setLoading(false);
        }

        if (data.isTrial && !isTrial) {
          setIsTrial(true);
          let days = 0;

          if (data.trialEnd) {
            const endDate = new Date(data.trialEnd);
            days = differenceInDays(endDate, new Date()) + 1;
          }

          const existsNotification = notifications.find((n) =>
            n.text?.includes("days of free trial remaining, you can get more information in ")
          );

          if (!existsNotification) {
            showInfoNotification({
              text: `You have ${days} days of free trial remaining, you can get more information in `,
              link: { to: "/app/settings/billing", label: "Billing Page" },
            });
          }
        }

        return data;
      }
    } catch (err: any) {
      showErrorNotification({ text: getErrorMessage(err) });
    } finally {
      setLoading(false);
    }

    return null;
  };

  useEffect(() => {
    if (!userData || needRefresh) {
      refreshUserData();
    }
  }, [needRefresh]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (userData) {
        // @ts-ignore
        window.botelUserData = userData;
      } else {
        // @ts-ignore
        window.botelUserData = null;
      }
    }
  }, [userData]);

  const changeIntegrationStatus = async (integrationType: string, enabled: boolean) => {
    // TODO: add EP to change integration status, align FE logic when its done
    if (userData && userData.client && userData.client.client_integration) {
      if (!enabled && !window.confirm("Are you sure to disconnect this integration?")) {
        return;
      }

      await connectOrDisconnectIntegration(userData.client.id, enabled);

      const updatedIntegrations = userData.client.client_integration.map((i) => {
        if (i.integration_type?.toLowerCase() === integrationType.toLowerCase()) {
          return {
            ...i,
            status: enabled ? INTEGRATION_STATUSES.TOKEN_ADDED : INTEGRATION_STATUSES.INITIAL,
          };
        }
        return i;
      });

      setUserData({
        ...userData,
        client: { ...userData.client, active: enabled, client_integration: updatedIntegrations },
      });
    }
  };

  const canSee = (
    permission?: PermissionType | PermissionType[],
    listingId?: number | number[]
  ) => {
    if (permission) {
      permission = Array.isArray(permission) ? permission : [permission];
      return !!userData?.permissions.some((p) => permission.includes(p));
    }

    return true;
  };

  const authorize = (
    permission?: PermissionType | PermissionType[],
    listingId?: number | number[]
  ) => {
    if (!canSee(permission, listingId)) {
      router.replace("/app/unauthorized").then(() => {
        showErrorNotification({
          text: "You do not have permission to access this page.",
        });
      });
    }
  };

  return (
    <UserDataContext.Provider
      value={{
        userData,
        changeIntegrationStatus,
        setNeedRefresh,
        refreshUserData,
        canSee,
        authorize,
        showPhoneModal,
        setShowPhoneModal,
      }}
    >
      {loading ? (
        <Box zIndex={100} position="fixed" top={0} left={0} bottom={0} right={0}>
          <OverlayLoader />
        </Box>
      ) : (
        <>
          {children}
          <UserPhoneModal
            userData={userData}
            showModal={showPhoneModal}
            setShowModal={setShowPhoneModal}
          />
        </>
      )}
    </UserDataContext.Provider>
  );
};

export function useUserData() {
  return useContext(UserDataContext);
}

import React, { useEffect, useState } from "react";
import { usePushNotifications } from "../features/notifications/hooks/usePushNotifications";
import { useUserData } from "../context/userDataContext";

type WithCustomSWProps = {
  children: React.ReactNode;
};

const WithCustomSW: React.FC<WithCustomSWProps> = ({ children }) => {
  const [swInitialized, setSwInitialized] = useState(false);
  const { userData } = useUserData();

  const { initializeOneSignal } = usePushNotifications();

  useEffect(() => {
    if (navigator.serviceWorker) {
      navigator.serviceWorker.register(`/OneSignalSDKWorker.js`, { scope: "/" }).then(() => {
        setSwInitialized(true);
      });
    }
  }, []);

  useEffect(() => {
    if (swInitialized && userData) {
      initializeOneSignal(userData.id, userData.pushSubscriptions);
    }
  }, [swInitialized, userData]);

  return <>{children}</>;
};

export default WithCustomSW;

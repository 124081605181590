import { useRouter } from "next/router";
import IllustrationLayout from "./index";

const LayoutHOC = ({ children }: any) => {
  const router = useRouter();

  if (router.pathname.match(/^\/app.*/m)) {
    return <IllustrationLayout>{children}</IllustrationLayout>;
  }

  return <>{children}</>;
};

export default LayoutHOC;

import React, { useCallback, useEffect, useRef, useState } from "react";

import type { Listing, ListingsContextType, ListingsFilters } from "../types/Listing";
import type { UpdateListingDTO, UpdateListingInternal } from "../types/UpdateListing.dto";

import { updateOneListing } from "../api/updateOneListing";
import { updateManyListings } from "../api/updateManyListings";
import { getListings } from "../api/GET.listings";
import { getListingsCities } from "../api/GET.cities";
import { getConnectedListings } from "../api/GET.connectedListings";
import { getListingsTags } from "../api/GET.listingsTags";

import { useNotifications } from "../../notifications/hooks/useNotifications";
import { useRealtimeContext } from "../../realtime/contexts/RealtimeContext";
import { useUserData } from "../../../context/userDataContext";
import { getErrorMessage } from "../../../utility/getErrorMessage";

import { SOCKET_MESSAGES_TYPES } from "../../../constants";
import { connectAllListings } from "../api/POST.connectAllListings";
import { updateListingInfo } from "../api/PUT.updateListingInfo";
import { searchNewListings } from "../api/POST.searchNewListings";
import {
  LISTING_CONNECT_ACTION_FIELDS,
  LISTING_CONNECT_ACTIONS,
  LISTING_STATUS,
} from "../contants";

const DEFAULT_PAGE_SIZE = 100;

// TODO:
// check onboarding flow with botel token

// Listings Context - is a global app provider
const ListingsContext = React.createContext<ListingsContextType>({
  listings: [],
  setListings: () => null,
  connected: {
    total: 0,
    connected: 0,
    active: 0,
    inactive: 0,
  },
  newListings: 0,
  updateOneListing: () => null,
  updateManyListings: () => null,
  loading: true,
  cities: [],
  loaderState: {
    total: 0,
    loaded: 0,
  },
  processListingsIntegration: () => null,
  pagination: {
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    totalPages: 1,
    totalRecords: 0,
    onNext: () => null,
    onPrev: () => null,
    goToPage: (arg: number) => null,
    setPageSize: (size: number) => null,
    loading: false,
  },
  tags: [],
  getTags: () => null,
  filters: {},
  updateFilters: () => null,
  connectAll: () => null,
  manualListingsUpdate: {
    searchNew: () => null,
    refreshListingData: () => null,
    searchInProgress: false,
    updatedListings: [],
  },
  fetchListings: () => null,
});

export const ListingsContextProvider = ({ children }) => {
  const { userData, setNeedRefresh } = useUserData();
  const { showErrorNotification, showInfoNotification } = useNotifications();
  const { subscribeToChannel, unsubscribeFromChannel } = useRealtimeContext();

  // the list of user's listings
  const [listings, setListings] = useState<Listing[]>([]);
  // list loading
  const [loading, setLoading] = useState(true);
  // amount of listings with enabled ai
  const [connected, setConnected] = useState<{
    total: number;
    connected: number;
    active: number;
    inactive: number;
  }>({
    total: 0,
    connected: 0,
    active: 0,
    inactive: 0,
  });
  // amount of new listings
  const [newListings, setNewListings] = useState(0);
  // all listings cities
  const [cities, setCities] = useState<string[]>([]);
  // all listings tags
  const [tags, setTags] = useState<string[]>([]);
  // on initial client integration we're displaying how many listings are loaded from his integration
  const [loaderState, setLoaderState] = useState({ total: 0, loaded: 0 });
  // prevent filtered call on the first render
  const [initialRequest, setInitialRequest] = useState(true);
  // for listings which require manual updates (e.g. 'hostaway') - showing loader while getting new information about the listing
  const [updatedListings, setUpdatedListings] = useState<number[]>([]);
  // for sources which require manual updates (e.g. 'hostaway') - showing loader while searching for a new listings
  const [searchInProgress, setSearchInProgress] = useState(false);
  // applied filters
  const [filters, setFilters] = useState<ListingsFilters>({
    status: LISTING_STATUS.ACTIVE,
  });
  // pagination state
  const [pagination, setPagination] = useState({
    page: 1,
    totalPages: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    totalRecords: 0,
    loading: false,
  });
  // depends on type of pagination we can either add to the end of the list or show a new page
  const [paginationAction, setPaginationAction] = useState<"add" | "change">("change");

  // Key-value, where key is the unique identifier of the entity being updated, value is the number of requests that try to update it
  const currentlyUpdatedIds = useRef({});

  const fetchInitialListings = async () => {
    try {
      const { data, page, totalRecords, totalPages, pageSize } = await getListings({
        status: filters.status || "",
        page: 1,
        pageSize: 1000,
      });
      setListings(data);
      setPagination({ page, totalPages, totalRecords, pageSize, loading: false });
    } catch (err) {
      showErrorNotification({ text: getErrorMessage(err) });
    } finally {
      setLoading(false);
      setInitialRequest(false);
    }
  };

  const fetchListingsCities = async () => {
    try {
      const data = await getListingsCities();
      setCities(data);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchConnectedListings = async () => {
    try {
      const response = await getConnectedListings();
      setConnected(response);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchListings = async () => {
    try {
      setPagination((prev) => ({ ...prev, loading: true }));
      const { page, pageSize } = pagination;
      const { cities, tags, search, status } = filters;

      const response = await getListings({
        page,
        pageSize,
        status: status || "",
        search: search || "",
        cities: cities || [],
        tags: tags || [],
      });

      setLoaderState((prev) => ({ total: prev.total, loaded: prev.loaded + response.data.length }));
      setPagination((prev) => ({
        ...prev,
        totalPages: response.totalPages,
        totalRecords: response.totalRecords,
      }));

      if (paginationAction === "change") {
        setListings(response.data);
      } else {
        setListings((prev) => [...prev, ...response.data]);
      }
    } catch (err) {
      showErrorNotification({
        title: "An error occured while getting listings",
        text: getErrorMessage(err),
      });
    } finally {
      setPagination((prev) => ({ ...prev, loading: false }));
    }
  };

  useEffect(() => {
    if (userData && userData.client?.id) {
      fetchInitialListings();
      fetchListingsCities();
      fetchConnectedListings();
    } else {
      setLoading(false);
    }
  }, [userData]);

  useEffect(() => {
    const { total, loaded } = loaderState;
    if (total === loaded && total > 0) {
      unsubscribeFromChannel("listings");
      showInfoNotification({ title: "Congratulations!", text: "We have added all listings" });
      setPaginationAction("change");
    }
  }, [loaderState]);

  useEffect(() => {
    if (!initialRequest) {
      fetchListings();
    }
  }, [pagination.page, pagination.pageSize, filters]);

  const createRequestData = ({
    id,
    field,
    value,
  }: UpdateListingInternal): UpdateListingDTO | null => {
    const target = listings.find((l) => +l.id === +id);

    if (target) {
      switch (field) {
        case "enabled": {
          return {
            listingId: +id,
            enabled: value,
            is_auto_pilot: value ? target.is_auto_pilot : false,
            is_auto_preapprove: target.is_auto_preapprove,
            paid_for_analisys: target.paid_for_analisys,
          };
        }
        case "is_auto_pilot": {
          return {
            listingId: +id,
            enabled: target.enabled,
            is_auto_pilot: value,
            is_auto_preapprove: target.is_auto_preapprove,
            paid_for_analisys: target.paid_for_analisys,
          };
        }
        case "is_auto_preapprove": {
          return {
            listingId: +id,
            enabled: target.enabled,
            is_auto_pilot: target.is_auto_pilot,
            is_auto_preapprove: value,
            paid_for_analisys: target.paid_for_analisys,
          };
        }
        case "paid_for_analisys": {
          return {
            listingId: +id,
            enabled: target.enabled,
            is_auto_pilot: target.is_auto_pilot,
            is_auto_preapprove: target.is_auto_preapprove,
            paid_for_analisys: value,
          };
        }
        default: {
          return null;
        }
      }
    }

    return null;
  };

  const lazilyUpdateOne = async (reqBody: UpdateListingDTO, stateBackup: Listing[]) => {
    try {
      // start background update
      currentlyUpdatedIds.current[+reqBody.listingId] += 1;
      const res = await updateOneListing(reqBody);
      currentlyUpdatedIds.current[+reqBody.listingId] -= 1;
      setNeedRefresh();

      // we can update the entity only at the moment when the last received request tries to access it.
      if (res && currentlyUpdatedIds.current[+reqBody.listingId] === 0) {
        setListings((prev) => prev.map((l) => (+l.id === +res.id ? res : l)));
      }
    } catch (err) {
      showErrorNotification({ text: getErrorMessage(err) });
      // in case the update failed we need to revert changes\
      setListings(stateBackup);
    }
  };

  const lazilyUpdateMany = async (
    reqBody: UpdateListingDTO[],
    stateBackup: Listing[],
    action: string
  ) => {
    try {
      reqBody.forEach((l) => {
        currentlyUpdatedIds.current[+l.listingId] += 1;
      });

      const res = await updateManyListings(reqBody, action);

      reqBody.forEach((l) => {
        currentlyUpdatedIds.current[+l.listingId] -= 1;
      });

      if (res) {
        setListings((prev) =>
          prev.map((l) => {
            const target = res.find((item) => +item.id === +l.id);
            if (target && currentlyUpdatedIds.current[+target.id] === 0) {
              return target;
            }
            return l;
          })
        );
      }

      await fetchListings();
    } catch (err) {
      showErrorNotification({ text: getErrorMessage(err) });
      setListings(stateBackup);
    }
  };

  const handleConnectAll = async (id: string) => {
    try {
      await connectAllListings(id, filters);
      setListings((prev) => prev.map((l) => ({ ...l, [LISTING_CONNECT_ACTION_FIELDS[id]]: true })));

      if (id == LISTING_CONNECT_ACTIONS.AI_RESPONSE_SUGGESTION) {
        setConnected((prev) => ({ ...prev, connected: prev.total }));
      }
    } catch (err) {
      showErrorNotification({ text: getErrorMessage(err) });
    }
  };

  const handleUpdateOneListing = useCallback(
    (arg: UpdateListingInternal) => {
      const stateBackUp = [...listings];

      const reqBody = createRequestData(arg);

      if (reqBody) {
        // @ts-ignore
        setListings((prev) =>
          prev.map((l) =>
            +l.id === reqBody.listingId
              ? {
                  ...l,
                  enabled: reqBody.enabled,
                  is_auto_pilot: reqBody.is_auto_pilot,
                  is_auto_preapprove: reqBody.is_auto_preapprove,
                  paid_for_analisys: reqBody.paid_for_analisys,
                }
              : l
          )
        );

        if (arg.field === "enabled") {
          setConnected((prev) => ({
            ...prev,
            connected: arg.value ? prev.connected + 1 : prev.connected - 1,
          }));
        }

        lazilyUpdateOne(reqBody, stateBackUp);
      }
    },
    [listings]
  );

  const handleUpdateManyListings = useCallback(
    (ids: (string | number)[], action: string) => {
      console.log({ ids, action, field: LISTING_CONNECT_ACTION_FIELDS[action] });

      const stateBackup = [...listings];

      const reqBody = ids.map((id) => ({
        listingId: +id,
        [LISTING_CONNECT_ACTION_FIELDS[action]]: true,
      }));

      setListings((prev) => {
        return prev.map((l) => {
          if (ids.includes(l.id)) {
            return {
              ...l,
              [LISTING_CONNECT_ACTION_FIELDS[action]]: true,
            };
          }

          return l;
        });
      });

      lazilyUpdateMany(reqBody, stateBackup, action);
    },
    [listings]
  );

  const handleListingsWSevent = (count?: number) => {
    if (!count) {
      setLoaderState((prev) => ({ total: prev.loaded, loaded: prev.loaded }));
      setPaginationAction("change");
      return;
    }

    setPagination((prev) => ({ ...prev, page: prev.page + 1 }));
    setPaginationAction("add");
  };

  const getTags = async () => {
    try {
      const response = await getListingsTags();
      setTags(response);
    } catch (err) {
      console.error(err);
    }
  };

  const processListingsIntegration = (data: Listing[], listingsCount: number) => {
    if (data.length < listingsCount) {
      subscribeToChannel("listings", [
        { event: SOCKET_MESSAGES_TYPES.LISTINGS_FETCHED, callback: handleListingsWSevent },
      ]);
      setLoaderState({ loaded: data.length, total: listingsCount });
    }

    const page = 1;
    const pageSize = data.length;
    const totalPages = Math.ceil(listingsCount / data.length);
    const totalRecords = listingsCount;

    setPagination({ page, pageSize, totalPages, totalRecords, loading: false });
    setListings(data);
  };

  const handleRefreshListingData = async (listingId: number) => {
    try {
      setUpdatedListings((prev) => [...prev, listingId]);
      const listing = await updateListingInfo(listingId);
      setListings((prev) => prev.filter((l) => (l.id === listing.id ? listing : l)));
    } catch (err) {
      console.error(err);
    } finally {
      setUpdatedListings((prev) => prev.filter((id) => id !== listingId));
    }
  };

  const handleSearchForNewListings = async () => {
    try {
      setSearchInProgress(true);

      const { data, page, totalRecords, totalPages, pageSize } = await searchNewListings();

      if (data.length) {
        showInfoNotification({ text: "Listings updated successfully" });
        await fetchListings();
      } else {
        showInfoNotification({ text: "No new listings found" });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setSearchInProgress(false);
    }
  };

  const handleNextPage = (action?: "change" | "add") => {
    setPagination((prev) => ({ ...prev, page: prev.page + 1 }));
    setPaginationAction(action || "change");
  };
  const handlePrevPage = () => {
    setPagination((prev) => ({ ...prev, page: prev.page - 1 }));
  };
  const goToPage = (pageNumber: number) => {
    setPagination((prev) => ({ ...prev, page: pageNumber }));
  };

  const setPageSize = (size: number) => {
    setPagination((prev) => ({ ...prev, page: 1, pageSize: size }));
    setPaginationAction("change");
  };

  const updateFilters = (arg: ListingsFilters) => {
    setPaginationAction("change");
    setFilters((prev) => ({ ...prev, ...arg }));
    setPagination((prev) => ({ ...prev, page: 1 }));
  };

  return (
    <ListingsContext.Provider
      value={{
        listings,
        setListings,
        connected,
        newListings,
        updateOneListing: handleUpdateOneListing,
        updateManyListings: handleUpdateManyListings,
        loading,
        cities,
        loaderState,
        processListingsIntegration,
        pagination: {
          ...pagination,
          onNext: handleNextPage,
          onPrev: handlePrevPage,
          goToPage,
          setPageSize,
        },
        tags,
        getTags,
        filters,
        updateFilters,
        connectAll: handleConnectAll,
        manualListingsUpdate: {
          searchNew: handleSearchForNewListings,
          refreshListingData: handleRefreshListingData,
          searchInProgress,
          updatedListings,
        },
        initialRequest,
        fetchListings,
      }}
    >
      {children}
    </ListingsContext.Provider>
  );
};

export const useListings = () => {
  const ctx = React.useContext(ListingsContext);

  if (!ctx) {
    console.error("Listings context is not configured");
  }

  return ctx;
};

import React, { useEffect } from "react";
import Image from "next/image";

import { IconButton, List, ListItem, styled } from "@mui/material";

import SoftTypography from "../../../components/SoftTypography";
import SoftButton from "../../../components/SoftButton";
import SoftBox from "../../../components/SoftBox";

import AppLogo from "../../../assets/images/app-logo.png";
import IosShareIcon from "@mui/icons-material/IosShare";
import CloseIcon from "@mui/icons-material/Close";
import InstallDesktopIcon from "@mui/icons-material/InstallDesktop";
import { AnimatePresence, motion } from "framer-motion";
import { useWithPwaPromptContext } from "../HOC/WithPwaPrompt";
import { isPWA } from "../utils";

const promptText = "Install the app on your device \nto easily access it anytime";

const InstallPwaPrompt = () => {
  const { platform, showPrompt, setShowPrompt, handleInstall, prompt } = useWithPwaPromptContext();

  const onClose = () => {
    setShowPrompt(false);
  };

  return (
    <AnimatePresence>
      {!isPWA() && platform && showPrompt && (
        <motion.div initial={{ y: -120 }} animate={{ y: 0 }} exit={{ y: -120 }}>
          <PromptContainer className="tw-fixed md:tw-static">
            <ActionBox>
              <IconButton sx={{ p: 0 }} onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </ActionBox>

            <ImageBox>
              <ImageWrapper
                sx={{ width: platform === "ios" ? 80 : 40, height: platform === "ios" ? 80 : 40 }}
              >
                <Image src={AppLogo} fill alt="logo" />
              </ImageWrapper>
            </ImageBox>

            <ContentBox>
              {platform === "android" &&
                (prompt ? (
                  <AndroidContent>
                    <SoftBox>
                      <AppName>Botel</AppName>
                      <PromptText>{promptText}</PromptText>
                    </SoftBox>

                    <SoftButton color="primary" size="small" onClick={handleInstall} sx={{ px: 2 }}>
                      Install
                    </SoftButton>
                  </AndroidContent>
                ) : (
                  <SoftBox>
                    <AppName>Botel</AppName>
                    <PromptText>{promptText}</PromptText>
                    <List>
                      <ListItem sx={{ my: 0.5 }}>
                        <PromptText>1. Tap on</PromptText>
                        <InstallDesktopIcon sx={{ fontSize: 16 }} />
                        <PromptText sx={{ ml: 1 }}>in browser URL bar</PromptText>
                      </ListItem>

                      <ListItem>
                        <PromptText>2. Click "Install" button</PromptText>
                      </ListItem>
                    </List>
                  </SoftBox>
                ))}

              {platform === "ios" && (
                <SoftBox>
                  <AppName>Botel</AppName>
                  <PromptText>{promptText}</PromptText>
                  <List>
                    <ListItem sx={{ my: 0.5 }}>
                      <PromptText>1. Tap on</PromptText>
                      <IosShareIcon />
                    </ListItem>

                    <ListItem>
                      <PromptText>2. Select "Add to Home Screen"</PromptText>
                    </ListItem>
                  </List>
                </SoftBox>
              )}
            </ContentBox>
          </PromptContainer>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const PromptContainer = styled(SoftBox)(() => ({
  top: "0",
  width: "100%",
  background: "white",
  zIndex: 100,
  display: "flex",
  padding: "8px 16px",
}));

const ImageBox = styled(SoftBox)(() => ({
  margin: "0 8px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const ImageWrapper = styled(SoftBox)(() => ({
  position: "relative",
}));

const ContentBox = styled(SoftBox)(() => ({
  paddingLeft: 8,
  flex: 1,
}));

const AndroidContent = styled(SoftBox)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const ActionBox = styled(SoftBox)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const PromptText = styled(SoftTypography)(() => ({
  fontSize: 12,
  marginRight: 8,
  lineHeight: 1,
  whiteSpace: "pre-wrap",
}));

const AppName = styled(SoftTypography)(() => ({
  fontSize: 12,
  fontWeight: 700,
}));

export default InstallPwaPrompt;

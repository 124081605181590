import React from "react";

const TuneIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.4999 2.8001C3.4999 2.4135 3.1865 2.1001 2.7999 2.1001C2.41331 2.1001 2.0999 2.4135 2.0999 2.8001V7.88742C1.68144 8.12948 1.3999 8.58189 1.3999 9.1001C1.3999 9.61831 1.68144 10.0707 2.0999 10.3128V11.2001C2.0999 11.5867 2.41331 11.9001 2.7999 11.9001C3.1865 11.9001 3.4999 11.5867 3.4999 11.2001V10.3128C3.91836 10.0707 4.1999 9.61831 4.1999 9.1001C4.1999 8.58189 3.91836 8.12948 3.4999 7.88742V2.8001Z"
      fill="#8392AB"
    />
    <path
      d="M7.7001 2.8001C7.7001 2.4135 7.38671 2.1001 7.0001 2.1001C6.6135 2.1001 6.3001 2.4135 6.3001 2.8001V3.68739C5.88164 3.92946 5.6001 4.3819 5.6001 4.9001C5.6001 5.41829 5.88164 5.87074 6.3001 6.11281V11.2001C6.3001 11.5867 6.6135 11.9001 7.0001 11.9001C7.38671 11.9001 7.7001 11.5867 7.7001 11.2001V6.11281C8.11856 5.87074 8.4001 5.41829 8.4001 4.9001C8.4001 4.3819 8.11856 3.92946 7.7001 3.68739V2.8001Z"
      fill="#8392AB"
    />
    <path
      d="M11.1998 2.1001C11.5864 2.1001 11.8998 2.4135 11.8998 2.8001V7.88742C12.3183 8.12948 12.5998 8.58189 12.5998 9.1001C12.5998 9.61831 12.3183 10.0707 11.8998 10.3128V11.2001C11.8998 11.5867 11.5864 11.9001 11.1998 11.9001C10.8132 11.9001 10.4998 11.5867 10.4998 11.2001V10.3128C10.0813 10.0707 9.7998 9.61831 9.7998 9.1001C9.7998 8.58189 10.0813 8.12948 10.4998 7.88742V2.8001C10.4998 2.4135 10.8132 2.1001 11.1998 2.1001Z"
      fill="#8392AB"
    />
  </svg>
);

export default TuneIcon;

import React from "react";
import Notification from "./Notification";
import { useNotifications } from "../hooks/useNotifications";
import { motion, AnimatePresence, LayoutGroup } from "framer-motion";
import { useRouter } from "next/router";

const FADE_ANIMATION_IN_MS = 300;

const NotificationContainer = () => {
  const router = useRouter();

  const { notifications, removeNotification } = useNotifications();

  const handleDismiss = (id) => setTimeout(() => removeNotification(id), FADE_ANIMATION_IN_MS);

  const filterNotificationsFn = (n) =>
    (n.type === "info" && !router.asPath.includes("/auth")) ||
    n.type === "error" ||
    n.type === "success";

  return (
    <LayoutGroup>
      <AnimatePresence>
        {notifications
          .sort((n1, n2) => (n1.type > n2.type ? -1 : 1))
          .filter(filterNotificationsFn)
          .map((n) => (
            <motion.div
              key={n.id}
              initial={{ opacity: 1 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              layout
            >
              <Notification
                key={n.id}
                id={n.id}
                type={n.type}
                title={n.title}
                text={n.text}
                link={n.link}
                onDismiss={handleDismiss}
                actionTitle={n.actionTitle}
                action={n.action}
                autoHide={n.autoHide}
                duration={n.duration}
              />
            </motion.div>
          ))}
      </AnimatePresence>
    </LayoutGroup>
  );
};

export default NotificationContainer;

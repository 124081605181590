import axios from "axios";
import { getServerPath } from "../../../constants";
import { GetListingsRequestParams, GetListingsResponse } from "../types/Listing";
import { getRequestParamSerializer } from "../../../utility/getRequestParamSerializer";

export const getListings = async (params: GetListingsRequestParams) => {
  const { data } = await axios.get<GetListingsResponse>(`${getServerPath()}/listings`, {
    params,
    paramsSerializer: getRequestParamSerializer,
  });
  return data;
};

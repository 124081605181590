import { createContext, useContext, useEffect, useState } from "react";
import { isPWA } from "../utils";

type WithPwaPromptContext = {
  prompt?: any;
  platform: "ios" | "android" | "chrome" | null;
  showPrompt: boolean;
  setShowPrompt: (arg: boolean) => void;
  handleInstall: () => void;
};

type WithPwaPromptContextProviderProps = {
  children: React.ReactNode;
};

const WithPwaPromptContext = createContext<WithPwaPromptContext>({
  prompt: null,
  platform: null,
  showPrompt: false,
  setShowPrompt: () => {},
  handleInstall: () => {},
});

export const useWithPwaPromptContext = () => {
  const ctx = useContext(WithPwaPromptContext);

  if (!ctx) {
    console.error("App layout context is not configured");
  }

  return ctx;
};

export const WithPwaPromptContextProvider: React.FC<WithPwaPromptContextProviderProps> = ({
  children,
}) => {
  const [prompt, setPrompt] = useState(null);
  const [showPrompt, setShowPrompt] = useState(false);
  const [platform, setPlatform] = useState<"ios" | "android" | "chrome" | null>(null);

  const promptToInstall = () => {
    if (prompt) {
      // @ts-ignore
      return prompt.prompt();
    }
  };

  const handleInstall = () => {
    if (prompt) {
      promptToInstall();
    } else {
      if (!showPrompt) {
        setShowPrompt(true);
      } else {
        setShowPrompt(false);

        if (promptToInstall) {
          promptToInstall();
        }
      }
    }
  };

  const initInstallationPromptState = async () => {
    if (navigator?.userAgent) {
      const { userAgent } = navigator;

      if (isPWA()) {
        return;
      }

      if (userAgent.match(/android/i) || userAgent.match(/chrome/i)) {
        setPlatform("android");
      } else if (
        userAgent.match(/iphone/i) ||
        (userAgent.match(/safari/i) && userAgent.match(/macintosh/i))
      ) {
        setPlatform("ios");
      }

      if (userAgent.match(/android/i) || userAgent.match(/iphone/i)) {
        setTimeout(() => {
          setShowPrompt(true);
        }, 3000);
      }
    }
  };

  useEffect(() => {
    const ready = (e) => {
      e.preventDefault();
      setPrompt(e);
    };

    window.addEventListener("beforeinstallprompt", ready);
    initInstallationPromptState();

    return () => {
      window.removeEventListener("beforeinstallprompt", ready);
    };
  }, []);

  return (
    <WithPwaPromptContext.Provider
      value={{ prompt, platform, showPrompt, setShowPrompt, handleInstall }}
    >
      {children}
    </WithPwaPromptContext.Provider>
  );
};

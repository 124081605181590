import React, { useEffect, useState } from "react";
import { useNotifications } from "../../notifications/hooks/useNotifications";
import { getErrorMessage } from "../../../utility/getErrorMessage";
import { getUnreadMessages } from "../api/GET.unreadMessages";
import { useRealtimeContext } from "../../realtime/contexts/RealtimeContext";
import { SOCKET_MESSAGES_TYPES } from "../../../constants";
import { useRouter } from "next/router";
import { useUserData } from "../../../context/userDataContext";
import { PERMISSION_TYPES } from "../../../types/permissions.type";

type UnreadMessagesProviderProps = {
  children: React.ReactNode;
};

type UnreadMessagesContextType = {
  unreadCount: number;
  increaseUnreadCount: () => void;
  decreaseUnreadCount: () => void;
};

const UnreadMessagesContext = React.createContext<UnreadMessagesContextType>({
  unreadCount: 0,
  increaseUnreadCount: () => null,
  decreaseUnreadCount: () => null,
});

export const useUnreadMessageContext = () => {
  const ctx = React.useContext(UnreadMessagesContext);

  if (!ctx) {
    console.error("Unread messages context is not configured");
  }

  return ctx;
};

export const UnreadMessagesProvider: React.FC<UnreadMessagesProviderProps> = ({ children }) => {
  const router = useRouter();
  const { canSee, userData } = useUserData();
  const { showErrorNotification } = useNotifications();
  const { subscribeToChannel, unsubscribeFromChannel } = useRealtimeContext();
  const [unreadCount, setUnreadCount] = useState(0);

  const increaseUnreadCount = () => setUnreadCount((prev) => prev + 1);

  const decreaseUnreadCount = () => setUnreadCount((prev) => (prev - 1 >= 0 ? prev - 1 : 0));

  const fetchUnreadCount = async () => {
    try {
      if (!userData?.client || !canSee(PERMISSION_TYPES.INBOX)) return;
      const { count } = await getUnreadMessages();
      setUnreadCount(count);
    } catch (err) {
      showErrorNotification({ text: getErrorMessage(err) });
    }
  };
  const unreadMessageEventCallback = () => {
    if (!router.asPath.includes("inbox")) {
      fetchUnreadCount();
    }
  };

  useEffect(() => {
    fetchUnreadCount();
    subscribeToChannel("unread", [
      { event: SOCKET_MESSAGES_TYPES.UPDATE_UNREAD, callback: unreadMessageEventCallback },
      {
        event: SOCKET_MESSAGES_TYPES.CONVERSATION_READ,
        callback: () => console.log("conversation read"),
      },
    ]);

    return () => {
      unsubscribeFromChannel("unread");
    };
  }, []);

  return (
    <UnreadMessagesContext.Provider
      value={{ unreadCount, increaseUnreadCount, decreaseUnreadCount }}
    >
      {children}
    </UnreadMessagesContext.Provider>
  );
};

export default UnreadMessagesProvider;

import React, { useState } from "react";
import { useNotifications } from "../../notifications/hooks/useNotifications";
import ModalBase from "../../../components/ModalBase";
import CloseIcon from "@mui/icons-material/Close";
import SoftTypography from "../../../components/SoftTypography";
import SoftButton from "../../../components/SoftButton";
import { IconButton } from "@mui/material";
import { styled } from "@mui/system";
import SoftBox from "../../../components/SoftBox";
import { USER_PHONE_TYPE, USER_PHONE_TYPE_LABELS } from "../../../types/user.type";
import Select from "../../../components/Select/Select";
import SoftInput from "../../../components/SoftInput";

const phoneTypeOptions = Object.keys(USER_PHONE_TYPE_LABELS).map((id) => ({
  id,
  label: USER_PHONE_TYPE_LABELS[id],
}));

const UserPhoneModal = ({ userData, showModal, setShowModal }) => {
  const [updating, setUpdating] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [verifying, setVerifying] = useState(true);
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const [dirty, setDirty] = useState(false);
  const [type, setType] = useState(USER_PHONE_TYPE.SMS);
  const [phone, setPhone] = useState("");
  const [token, setToken] = useState("");

  const handleClose = () => {
    setShowModal(false);
  };

  const handleSubmit = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setVerifying(true);
  };

  return (
    <>
      {verifying ? (
        <ModalBase className="tw-flex tw-max-w-screen-md" open={showModal} onClose={handleClose}>
          <ModalHeader>
            <div className="tw-text-xl tw-w-full tw-text-left tw-font-bold">
              Verify your phone number
            </div>

            <CloseButton onClick={handleClose}>
              <CloseIcon sx={{ fill: "#252F40" }} />
            </CloseButton>
          </ModalHeader>

          <Container component="form" role="form" onSubmit={handleSubmit}>
            <div className="tw-pt-2 tw-w-full">
              <div className="tw-flex tw-flex-row tw-flex-wrap tw-gap-4">
                <div className="tw-text-sm tw-text-gray-600">
                  In the format you selected, we have dispatched a six-digit verification code to
                  your provided phone number. To proceed with the identification of your phone
                  number, kindly enter the code you received here. If you have not received the
                  code, you may attempt to resend it.
                </div>

                <div className="tw-flex tw-flex-col tw-gap-2 tw-w-full">
                  <div className="tw-text-base">Code</div>
                  <SoftInput
                    value={token}
                    type="number"
                    autoFocus
                    onChange={(e) => setPhone(e.target?.value?.toString() || "")}
                    placeholder="123456"
                  />
                </div>
              </div>

              {errorMessage && (
                <div className="tw-mt-6">
                  <SoftTypography fontSize={14} color="error">
                    {errorMessage}
                  </SoftTypography>
                </div>
              )}

              <div className="tw-flex tw-gap-2 tw-mt-6">
                <SoftButton
                  type="submit"
                  color="primary"
                  variant="outlined"
                  sx={{ textTransform: "none" }}
                  size="large"
                  loading={updating}
                  fullWidth
                  disabled
                >
                  Resend
                </SoftButton>

                <SoftButton
                  type="submit"
                  color="primary"
                  variant="contained"
                  sx={{ textTransform: "none" }}
                  size="large"
                  loading={updating}
                  fullWidth
                >
                  Verify
                </SoftButton>
              </div>
            </div>
          </Container>
        </ModalBase>
      ) : (
        <ModalBase className="tw-flex tw-max-w-screen-md" open={showModal} onClose={handleClose}>
          <ModalHeader>
            <div className="tw-text-xl tw-w-full tw-text-left tw-font-bold">
              {userData?.phone ? "Update your phone" : "Add your phone"}
            </div>

            <CloseButton onClick={handleClose}>
              <CloseIcon sx={{ fill: "#252F40" }} />
            </CloseButton>
          </ModalHeader>

          <Container component="form" role="form" onSubmit={handleSubmit}>
            <div className="tw-pt-2 tw-w-full">
              <div className="tw-flex tw-flex-row tw-flex-wrap tw-gap-4">
                <div className="tw-text-sm tw-text-gray-600">
                  Once you have successfully input your phone number into the system, you can expect
                  to receive real-time notifications directly on your mobile device through
                  messaging. These notifications will promptly provide you with updates, alerts, or
                  relevant information, ensuring you stay informed and connected with the latest
                  developments related to the system or service.
                </div>

                <div className="tw-flex tw-flex-col tw-gap-2 tw-w-full">
                  <div className="tw-text-base">Verification Type</div>
                  <Select
                    onSelect={(value) => setType(value?.toString() || USER_PHONE_TYPE.SMS)}
                    options={phoneTypeOptions}
                    selected={type}
                    single
                  />
                </div>

                <div className="tw-flex tw-flex-col tw-gap-2 tw-w-full">
                  <div className="tw-text-base">Phone</div>
                  <SoftInput
                    value={phone}
                    type="tel"
                    onChange={(e) => setPhone(e.target?.value?.toString() || "")}
                    placeholder="+1408XXXXXXX"
                  />
                </div>
              </div>

              {errorMessage && (
                <div className="tw-mt-6">
                  <SoftTypography fontSize={14} color="error">
                    {errorMessage}
                  </SoftTypography>
                </div>
              )}

              <div className="tw-mt-6">
                <SoftButton
                  type="submit"
                  color="primary"
                  variant="contained"
                  sx={{ textTransform: "none" }}
                  size="large"
                  loading={updating}
                  fullWidth
                >
                  {userData?.phone ? "Update" : "Add"}
                </SoftButton>
              </div>
            </div>
          </Container>
        </ModalBase>
      )}
    </>
  );
};

export default UserPhoneModal;

const Container = styled(SoftBox)(() => ({
  width: "100%",
}));

const ModalHeader = styled(SoftBox)(() => ({
  position: "relative",
}));

const CloseButton = styled(IconButton)(() => ({
  position: "absolute",
  right: 0,
  top: 0,
  fontSize: 18,
  padding: 0,
  height: 22,
}));

export const INSPECTION_STATES = {
  PROCESSING: "PROCESSING",
};

export const MESSAGE_ROLES = {
  GUEST: "guest",
  CLIENT: "client",
  HOST: "host",
};

export const INTEGRATION_TYPE = {
  GUESTY: "guesty",
  HOSTAWAY: "hostAway",
  LODGIFY: "lodgify",
};

export const STATIC_CLIENT_ID = "792e3fd0-b6cf-4f3e-a219-6882884a3e70";

// Dice's Coefficient,
export const SUGGESTION_MESSAGE_SIMILARITY_THRESHOLD = 0.75;

export const OPEN_AI_TURBO_3_5_TOKEN_LIMIT = 4096;
export const OPEN_AI_TURBO_3_5_16K_TOKEN_LIMIT = 16384;

import axios from "axios";
import { getServerPath } from "../../../constants";
import type { UpdateListingDTO } from "../types/UpdateListing.dto";
import type { Listing } from "../types/Listing";

export const updateManyListings = async (data: UpdateListingDTO[], action: string) => {
  const res = await axios.post<Listing[]>(
    getServerPath() + "/integrations/listings/features",
    data,
    {
      params: {
        action,
      },
    }
  );
  return res.data;
};

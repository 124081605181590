import { useContext } from "react";
import NotificationContext from "../context/NotificationContext";
import type { NewNotification } from "../types/NewNotification";
import type { NotificationLink } from "../types/NotificationLink";

type InfoNotificationParams = {
  text?: string;
  title?: string;
  link?: NotificationLink;
  action?: () => any;
  actionTitle?: string;
  autoHide?: boolean;
  duration?: number;
};

type ErrorNotificationParams = Omit<InfoNotificationParams, "link">;
type SuccessNotificationParams = ErrorNotificationParams;

export const useNotifications = () => {
  const { notifications, addNotification, removeNotification } = useContext(NotificationContext);

  const showErrorNotification = ({
    text,
    title,
    action,
    actionTitle,
    autoHide,
    duration,
  }: ErrorNotificationParams) => {
    const newNotification: NewNotification = {
      type: "error",
      text,
      title,
      actionTitle,
      action,
      autoHide,
      duration,
    };
    addNotification(newNotification);
  };

  const showInfoNotification = ({
    text,
    title,
    link,
    autoHide,
    duration,
  }: InfoNotificationParams) => {
    const newNotification: NewNotification = {
      type: "info",
      text,
      title,
      link,
      autoHide,
      duration,
    };
    addNotification(newNotification);
  };

  const showInfoNotificationIfNotExists = ({
    text,
    title,
    link,
    autoHide,
    duration,
  }: InfoNotificationParams) => {
    if (!notifications.some((n) => n.text == text)) {
      const newNotification: NewNotification = {
        type: "info",
        text,
        title,
        link,
        autoHide,
        duration,
      };

      addNotification(newNotification);
    }
  };

  const showSuccessNotification = ({
    text,
    title,
    autoHide,
    duration,
  }: SuccessNotificationParams) => {
    const newNotification: NewNotification = {
      type: "success",
      text,
      title,
      autoHide,
      duration,
    };
    addNotification(newNotification);
  };

  return {
    notifications,
    showErrorNotification,
    showInfoNotification,
    removeNotification,
    showSuccessNotification,
    showInfoNotificationIfNotExists,
  };
};

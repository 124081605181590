/**
 =========================================================
 * Soft UI Dashboard PRO React - v4.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import LogoutIcon from "@mui/icons-material/Logout";
// react-router components
import Link from "next/link";

// @mui material components
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import DefaultNavbarMenu from "examples/Navbars/DefaultNavbar/DefaultNavbarMenu";
import { signOut } from "next-auth/react";
import { LocalStorageService } from "../../../../services/LocalStorageService";
import { useUserData } from "../../../../context/userDataContext";
import { isPWA } from "../../../../features/pwa/utils";
import { useWithPwaPromptContext } from "../../../../features/pwa/HOC/WithPwaPrompt";
import InstallDesktopIcon from "@mui/icons-material/InstallDesktop";

function ApplicationsMenu({ routes, open, close, mobileMenu }) {
  const { canSee, userData } = useUserData();
  const { platform, handleInstall } = useWithPwaPromptContext();

  const handleLogout = (e) => {
    LocalStorageService.clearSessionData();
    e.preventDefault();
    signOut();
  };
  const renderApplicationsMenuRoute = (routeName) => [
    ...routes.map(
      ({ key, collapse }) =>
        key === routeName &&
        collapse
          .filter((r) => canSee(r.permissions))
          .map(({ key: collapseKey, route, name, Icon }) => (
            <MenuItem
              key={collapseKey}
              component={Link}
              href={route}
              onClick={mobileMenu ? undefined : close}
            >
              <SoftBox display="flex" alignItems="center" py={0.25}>
                <Icon
                  sx={({ functions: { linearGradient }, palette: { gradients, transparent } }) => ({
                    backgroundImage: `${linearGradient(
                      gradients.info.main,
                      gradients.info.state
                    )} !important`,
                    WebkitBackgroundClip: "text !important",
                    WebkitTextFillColor: `${transparent.main} !important`,
                  })}
                  fontSize="small"
                />

                <SoftBox color="text" pl={2} lineHeight={0}>
                  {name}
                </SoftBox>
              </SoftBox>
            </MenuItem>
          ))
    ),
    <MenuItem key={"logout"} onClick={handleLogout}>
      <SoftBox display="flex" alignItems="center" py={0.25}>
        <LogoutIcon
          sx={({ functions: { linearGradient }, palette: { gradients, transparent } }) => ({
            backgroundImage: `${linearGradient(
              gradients.info.main,
              gradients.info.state
            )} !important`,
            WebkitBackgroundClip: "text !important",
            WebkitTextFillColor: `${transparent.main} !important`,
          })}
          fontSize="small"
        />

        <SoftBox color="text" pl={2} lineHeight={0}>
          Logout
        </SoftBox>
      </SoftBox>
    </MenuItem>,
  ];

  return mobileMenu ? (
    <SoftBox px={1.5}>{renderApplicationsMenuRoute("applications")}</SoftBox>
  ) : (
    <DefaultNavbarMenu open={open} close={close}>
      <div className="tw-flex tw-flex-col tw-full tw-max-w-56 tw-text-sm tw-p-1 tw-mb-2 tw-text-secondary">
        <div className="tw-w-full tw-truncate tw-font-bold tw-text-black">{userData?.name}</div>
        <div className="tw-w-full tw-truncate">{userData?.email}</div>
      </div>
      <div className="tw-w-full tw-border-0 tw-border-b tw-border-solid tw-mb-3 tw-border-gray-100"></div>

      {!isPWA() && platform && (
        <>
          <MenuItem key={"download"} onClick={handleInstall}>
            <SoftBox display="flex" alignItems="center" py={0.25}>
              <InstallDesktopIcon
                sx={({ functions: { linearGradient }, palette: { gradients, transparent } }) => ({
                  backgroundImage: `${linearGradient(
                    gradients.info.main,
                    gradients.info.state
                  )} !important`,
                  WebkitBackgroundClip: "text !important",
                  WebkitTextFillColor: `${transparent.main} !important`,
                })}
                fontSize="small"
              />

              <SoftBox color="text" pl={2} lineHeight={0}>
                Download PWA
              </SoftBox>
            </SoftBox>
          </MenuItem>

          <div className="tw-w-full tw-border-0 tw-border-b tw-border-solid tw-my-3 tw-border-gray-100"></div>
        </>
      )}

      {renderApplicationsMenuRoute("applications")}
    </DefaultNavbarMenu>
  );
}

// Setting default values for the props of ApplicationsMenu
ApplicationsMenu.defaultProps = {
  mobileMenu: false,
  open: false,
  close: false,
};

// Typechecking props for the ApplicationsMenu
ApplicationsMenu.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  close: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  mobileMenu: PropTypes.bool,
};
export default ApplicationsMenu;

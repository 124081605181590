import React from "react";

const BedroomIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 9.5V6.3125C1 6.10417 1.04167 5.90833 1.125 5.725C1.20833 5.54167 1.33333 5.39167 1.5 5.275V3.825C1.5 3.45 1.62708 3.13542 1.88125 2.88125C2.13542 2.62708 2.45 2.5 2.825 2.5H5.075C5.25833 2.5 5.42917 2.54167 5.5875 2.625C5.74583 2.70833 5.88333 2.82083 6 2.9625C6.11667 2.82083 6.25208 2.70833 6.40625 2.625C6.56042 2.54167 6.72917 2.5 6.9125 2.5H9.1625C9.5375 2.5 9.85417 2.62708 10.1125 2.88125C10.3708 3.13542 10.5 3.45 10.5 3.825V5.275C10.6667 5.39167 10.7917 5.54167 10.875 5.725C10.9583 5.90833 11 6.10417 11 6.3125V9.5H10.25V8.5H1.75V9.5H1ZM6.375 5.0625H9.75V3.825C9.75 3.65833 9.69375 3.52083 9.58125 3.4125C9.46875 3.30417 9.32917 3.25 9.1625 3.25H6.875C6.73333 3.25 6.61458 3.30833 6.51875 3.425C6.42292 3.54167 6.375 3.675 6.375 3.825V5.0625ZM2.25 5.0625H5.625V3.825C5.625 3.675 5.57708 3.54167 5.48125 3.425C5.38542 3.30833 5.26667 3.25 5.125 3.25H2.825C2.66667 3.25 2.53125 3.30625 2.41875 3.41875C2.30625 3.53125 2.25 3.66667 2.25 3.825V5.0625ZM1.75 7.75H10.25V6.3125C10.25 6.17083 10.2021 6.05208 10.1062 5.95625C10.0104 5.86042 9.89167 5.8125 9.75 5.8125H2.25C2.10833 5.8125 1.98958 5.86042 1.89375 5.95625C1.79792 6.05208 1.75 6.17083 1.75 6.3125V7.75Z"
      fill="#7149C6"
    />
  </svg>
);

export default BedroomIcon;

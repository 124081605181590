import axios from "axios";
import { getServerPath } from "../../../constants";

export const getConnectedListings = async () => {
  const { data } = await axios.get<{
    total: number;
    connected: number;
    active: number;
    inactive: number;
  }>(getServerPath() + "/listings/connected");
  return data;
};

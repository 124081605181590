import React from "react";

const ReservationIcon = ({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 10.5H20.5V10.5853C20.0738 10.736 19.736 11.0738 19.5854 11.5H18.55C18.6738 10.8898 19.0199 10.3604 19.5 9.99979V10V10.5H20ZM18.55 12.5H19.5854C19.736 12.9262 20.0738 13.264 20.5 13.4147V13.5H20H19.5V14V14.0002C19.0199 13.6396 18.6738 13.1102 18.55 12.5ZM20.5 16.5V15.4646C20.6633 15.4879 20.8302 15.5 21 15.5H21.5V16.5H20.5ZM20.4146 17.5H21.45C21.2512 18.4796 20.4796 19.2512 19.5 19.45V18.4147C19.9262 18.264 20.264 17.9262 20.4146 17.5ZM18.5 18.5V19.5H16.5V19V18.5H18.5ZM13.5 19.5H5.5V18.5H13.5V19V19.5ZM4.5 18.4146V19.45C3.52043 19.2512 2.74884 18.4796 2.55001 17.5H3.58535C3.73599 17.9262 4.07379 18.264 4.5 18.4146ZM4.5 9.9998C4.9801 10.3604 5.32615 10.8899 5.44999 11.5H4.41465C4.26401 11.0738 3.92621 10.736 3.5 10.5854V10.5H4H4.5V10V9.9998ZM3.5 13.4146C3.92621 13.264 4.26401 12.9262 4.41465 12.5H5.44999C5.32615 13.1101 4.9801 13.6396 4.5 14.0002V14V13.5H4H3.5V13.4146ZM3.5 15.4646V16.5H2.5V15.5H3C3.16976 15.5 3.3367 15.4879 3.5 15.4646ZM3.5 8.53544C3.3367 8.51209 3.16976 8.5 3 8.5H2.5V7.5H3.5V8.53544ZM4.5 5.58535C4.0738 5.736 3.736 6.0738 3.58535 6.5H2.55001C2.74885 5.52044 3.52044 4.74885 4.5 4.55001V5.58535ZM5.5 5.5V4.5H13.5V5V5.5H5.5ZM16.5 4.5H18.5V5.5H16.5V5V4.5ZM19.5 5.58535V4.55001C20.4796 4.74884 21.2512 5.52043 21.45 6.5H20.4146C20.264 6.07379 19.9262 5.73599 19.5 5.58535ZM20.5 8.53544V7.5H21.5V8.5H21C20.8302 8.5 20.6633 8.51208 20.5 8.53544ZM14.5 12.5V11.5H15.5V12.5H14.5Z"
      fill={color || "#111827"}
      stroke={color || "#8392AB"}
    />
  </svg>
);

export default ReservationIcon;

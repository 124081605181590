/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { forwardRef } from "react";

// Custom styles for SoftInput
import SoftInputRoot from "./SoftInputRoot";
import SoftInputWithIconRoot from "./SoftInputWithIconRoot";
import SoftInputIconBoxRoot from "./SoftInputIconBoxRoot";
import SoftInputIconRoot from "./SoftInputIconRoot";

// Soft UI Dashboard PRO React contexts
import { useSoftUIController } from "../../context";
import { InputProps } from "@mui/material";

type SoftInputProps = {
  size?: "small" | "medium" | "large";
  icon?: {
    component: React.ReactNode | boolean;
    direction: "none" | "left" | "right";
  };
  error?: boolean;
  success?: boolean;
  disabled?: boolean;
  direction?: any;
} & InputProps;

type SoftInputRef = HTMLDivElement | null;

const SoftInput = forwardRef<SoftInputRef, SoftInputProps>(
  ({ size, icon, error, success, direction, disabled, ...rest }, ref) => {
    let template;
    // const [controller] = useSoftUIController();
    // const { direction } = controller;
    const iconDirection = icon?.direction;

    if (icon?.component && icon?.direction === "left") {
      template = (
        // @ts-ignore
        <SoftInputWithIconRoot ref={ref} ownerState={{ error, success, disabled }}>
          {/* @ts-ignore */}
          <SoftInputIconBoxRoot ownerState={{ size }}>
            {/* @ts-ignore */}
            <SoftInputIconRoot fontSize="small" ownerState={{ size }}>
              {icon.component}
            </SoftInputIconRoot>
          </SoftInputIconBoxRoot>
          <SoftInputRoot
            {...rest}
            // @ts-ignore
            ownerState={{ size, error, success, iconDirection, direction, disabled }}
          />
        </SoftInputWithIconRoot>
      );
    } else if (icon?.component && icon?.direction === "right") {
      template = (
        // @ts-ignore
        <SoftInputWithIconRoot ref={ref} ownerState={{ error, success, disabled }}>
          <SoftInputRoot
            {...rest}
            // @ts-ignore
            ownerState={{ size, error, success, iconDirection, direction, disabled }}
          />
          {/* @ts-ignore */}
          <SoftInputIconBoxRoot ownerState={{ size }}>
            {/* @ts-ignore */}
            <SoftInputIconRoot fontSize="small" ownerState={{ size }}>
              {icon.component}
            </SoftInputIconRoot>
          </SoftInputIconBoxRoot>
        </SoftInputWithIconRoot>
      );
    } else {
      template = (
        // @ts-ignore
        <SoftInputRoot {...rest} ref={ref} ownerState={{ size, error, success, disabled }} />
      );
    }

    return template;
  }
);

// Setting default values for the props of SoftInput
SoftInput.defaultProps = {
  size: "medium",
  icon: {
    component: false,
    direction: "none",
  },
  error: false,
  success: false,
  disabled: false,
};

export default SoftInput;

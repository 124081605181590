"use client";

import { ErrorBoundary } from "react-error-boundary";
import SoftTypography from "../components/SoftTypography";
import SoftButton from "../components/SoftButton";
import { Stack } from "@mui/material";
import { useRouter } from "next/router";

const fallbackRender = ({ error, resetErrorBoundary }) => {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.
  const router = useRouter();

  return (
    <Stack alignItems="center" gap={2}>
      <SoftTypography>Something went wrong:</SoftTypography>
      <code style={{ color: "red" }}>{error.message}</code>

      <Stack direction="row" gap={2}>
        <SoftButton onClick={resetErrorBoundary}>Reload</SoftButton>
        <SoftButton onClick={() => router.back()}>Go back</SoftButton>
      </Stack>
    </Stack>
  );
};

const ErrorBoundaryHOC = ({ children }) => {
  return (
    <ErrorBoundary
      fallbackRender={fallbackRender}
      onReset={(details) => {
        // Reset the state of your app so the error doesn't happen again
      }}
    >
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryHOC;

import React from "react";
import PropTypes from "prop-types";
import SoftButton from "../../../../components/SoftButton";
import { AddCircleIcon, ChainIcon } from "../../../../assets/icons";
import SoftTypography from "../../../../components/SoftTypography";
import { useRouter } from "next/router";

const ListingCardButton = (props) => {
  const { variant } = props;
  const router = useRouter();

  const clickHandler = () => {
    switch (variant) {
      case "add":
      case "enable_ai":
      case "setup_auto_pilot": {
        router.push("/app/listings");
      }
      case "empty": {
        router.push("/app/integrations");
      }
      default: {
      }
    }
  };

  const getListingButtonIcon = () => {
    if (variant === "enable_ai") {
      return <ChainIcon />;
    }

    return <AddCircleIcon />;
  };

  const getListingButtonLabel = () => {
    switch (variant) {
      case "add":
        return "Add listings";
      case "enable_ai":
        return "Enable AI";
      case "empty":
        return "Get started";
      case "setup_auto_pilot":
        return "Setup auto pilot";
      default:
        return "";
    }
  };

  return (
    <SoftButton
      color="primary"
      sx={{ pt: 1.375, pb: 1.25 }}
      size="large"
      fullWidth
      onClick={clickHandler}
    >
      {getListingButtonIcon()}
      <SoftTypography sx={{ ml: 1 }} variant="h5" color="white" fontWeight="bold">
        {getListingButtonLabel()}
      </SoftTypography>
    </SoftButton>
  );
};

ListingCardButton.propTypes = {
  variant: PropTypes.oneOf(["add", "enable_ai", "setup_auto_pilot", "empty"]),
};

export default ListingCardButton;

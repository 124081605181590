export type UserManagementUIContextType = {
  addMemberOpen: boolean;
  setAddMemberOpen: (arg: boolean) => void;
  addMemberLoading: boolean;
  setAddMemberLoading: (arg: boolean) => void;
  listingsModalOpen: boolean;
  setListingsModalOpen: (arg: boolean) => void;
};

export type AddMemberFormValues = {
  name: string;
  email: string;
  role: string | null;
  listings: any[];
};

export type UserPaymentManagementContextType = {
  loading: boolean;
  products: any[];
  setProductQuantity: (id: number, quantity: number) => void;
  subscription?: object;
  discountPercent: number;
};

export enum ProductType {
  RESPONSE_SUGGESTION = "RESPONSE_SUGGESTION",
  TASK_MANAGER = "TASK_MANAGER",
  AUTO_PRE_APPROVE = "AUTO_PRE_APPROVE",
}

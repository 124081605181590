/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for the SoftBadge
import SoftBadgeRoot from "./SoftBadgeRoot";
import { BadgeProps } from "@mui/material";

type SoftBadgeProps = {
  color?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark";
  variant?: "gradient" | "contained";
  size?: "xs" | "sm" | "md" | "lg";
  circular?: boolean;
  indicator?: boolean;
  border?: boolean;
  children?: React.ReactNode;
  container?: boolean;
} & BadgeProps;

const SoftBadge = forwardRef<HTMLDivElement, SoftBadgeProps>(
  ({ color, variant, size, circular, indicator, border, container, children, ...rest }, ref) => (
    <SoftBadgeRoot
      {...rest}
      // @ts-ignore
      ownerState={{ color, variant, size, circular, indicator, border, container, children }}
      ref={ref}
      color="default"
    >
      {children}
    </SoftBadgeRoot>
  )
);

// Setting default values for the props of SoftBadge
SoftBadge.defaultProps = {
  color: "info",
  // @ts-ignore
  variant: "gradient",
  size: "sm",
  circular: false,
  indicator: false,
  border: false,
  children: false,
  container: false,
};

export default SoftBadge;

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import { useRouter } from "next/router";
import SoftBadge from "../../../components/SoftBadge";
import { useTheme } from "@mui/material";
import Link from "next/link";
import { useUserData } from "../../../context/userDataContext";

function DefaultNavbarLink({
  name,
  basePath,
  onClick,
  openHandler,
  closeHandler,
  light,
  collapseStatus,
  href,
  tag,
  permissions,
  ...rest
}) {
  const router = useRouter();
  const { palette } = useTheme();
  const { canSee } = useUserData();

  const isActive = basePath ? router.asPath.includes(basePath) : router.asPath.includes(href);

  if (!canSee(permissions)) {
    return <></>;
  }

  return (
    <Link href={href}>
      <SoftBox
        {...rest}
        mx={1}
        p={1}
        display="flex"
        alignItems="baseline"
        color={light ? "white" : "dark"}
        sx={{
          cursor: "pointer",
          userSelect: "none",
          color: isActive && palette.primary.main,
          "&:hover": { color: palette.primary.main },
        }}
      >
        <SoftTypography
          variant="body2"
          textTransform="capitalize"
          color="inherit"
          sx={{ textShadow: isActive && `0 0 0.2px ${palette.primary.main}` }}
        >
          {name}
        </SoftTypography>
        {tag && (
          <SoftBadge
            badgeContent={tag}
            container
            color={"primary"}
            variant={"contained"}
            size={"s"}
            circular
            margin={19}
            sx={{
              paddingLeft: 2,
              paddingX: 1,
              ".MuiBadge-badge": { textTransform: "capitalize", px: 1, py: 0.75 },
            }}
          />
        )}
      </SoftBox>
    </Link>
  );
}

// Setting default values for the props of DefaultNavbarLink
DefaultNavbarLink.defaultProps = {
  openHandler: false,
  closeHandler: false,
  children: false,
  collapseStatus: false,
  light: false,
};

// Typechecking props for the DefaultNavbarLink
DefaultNavbarLink.propTypes = {
  name: PropTypes.string.isRequired,
  openHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  closeHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  children: PropTypes.node,
  collapseStatus: PropTypes.bool,
  light: PropTypes.bool,
};

export default DefaultNavbarLink;

import axios from "axios";
import type { RegisterUserDeviceRequest } from "../dtos/RegisterUserDevice.dto";
import { getServerPath } from "../../../constants";

export const registerUserDevice = async (body: RegisterUserDeviceRequest) => {
  try {
    const { data } = await axios.post(getServerPath() + "/user/notifications", body);
  } catch (err) {
    console.error(err);
  }
};

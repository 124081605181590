import { useState } from "react";
import OneSignal from "react-onesignal";

import { registerUserDevice } from "../api/POST.registerUserDevice";

import type { PushSubscription } from "../types/PushSubscription";

export const usePushNotifications = () => {
  const [oneSignalInitialized, setOneSignalInitialized] = useState(false);

  const initializeOneSignal = async (userId: string, pushSubscriptions: PushSubscription[]) => {
    if (oneSignalInitialized) {
      return;
    }

    const appId = process.env.NEXT_PUBLIC_ONE_SIGNAL_APP_ID;

    if (appId) {
      await OneSignal.init({
        appId,
        autoResubscribe: true,
      });

      await OneSignal.registerForPushNotifications({ slidedown: true });

      const oneSignalUserId = await OneSignal.getUserId();

      if (
        oneSignalUserId &&
        navigator &&
        !pushSubscriptions.some((s) => s.oneSignalUserId === oneSignalUserId)
      ) {
        const registerDeviceRequestBody = {
          userId,
          oneSignalUserId,
          language: navigator.language,
        };
        registerUserDevice(registerDeviceRequestBody);
      }

      setOneSignalInitialized(true);
    }
  };

  return { initializeOneSignal };
};
